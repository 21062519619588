import React from 'react'
import { CardFooterActions } from '../../components/styles'
import { CommentIcon } from '../comments/CommentIcon'
import LikeIcon from '../likes/LikeIcon'
import { Likes, LikeTypes } from '@commonstock/common/src/api/like'
import ShareIcon from '../share/ShareIcon'
import { SpacerV } from '../../composing/Spacing'
import { MemoFeedItem } from '@commonstock/common/src/api/feed'
import { destructMemoFeedItem } from './utils'
import { MemoDetailLink } from './MemoCard'
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants'
import MemoCardActions from 'src/scopes/memo/MemoCardActions'
import { isLoggedIn } from '@commonstock/common/src/auth'
import { useFlags } from 'src/utils/useFlags'
import RepostButton from 'src/components/repost/RepostButton'
import LikeList from 'src/components/LikeList'

function MemoFooterActions({
  memoFeedItem,
  detail,
  cardLayout,
  likes
}: {
  memoFeedItem: MemoFeedItem
  detail?: boolean
  cardLayout?: CardLayoutTypes
  likes?: Likes
}) {
  const { webRepostButton } = useFlags()
  let { memo, user } = destructMemoFeedItem(memoFeedItem)

  if (!memo || !user) return null
  return (
    <>
      <SpacerV />
      <CardFooterActions cardLayout={cardLayout}>
        {detail ? (
          <CommentIcon count={memo.comments?.count} cardLayout={cardLayout} />
        ) : (
          <MemoDetailLink memoFeedItem={memoFeedItem}>
            <CommentIcon count={memo.comments?.count} cardLayout={cardLayout} />
          </MemoDetailLink>
        )}
        {!isUpvoteLayout(cardLayout) && (
          <LikeIcon likes={memo.likes} parentUuid={memo.uuid} parentType={LikeTypes.MEMO_PLUS} authorUuid={user.uuid} />
        )}
        {webRepostButton && (
          <RepostButton
            memo
            uuid={memo.uuid}
            disabled={user.private}
            cardLayout={cardLayout}
            username={user.username}
            user={user}
          />
        )}
        {!isUpvoteLayout(cardLayout) && !webRepostButton && <div />}
        <ShareIcon memo uuid={memo.uuid} disabled={user.private} cardLayout={cardLayout} />
        {isUpvoteLayout(cardLayout) && !webRepostButton && <div />}
        {isUpvoteLayout(cardLayout) && detail && isLoggedIn() && likes && (
          <LikeList likes={likes} uuid={memo.uuid} small />
        )}
        {isUpvoteLayout(cardLayout) && isLoggedIn() && <MemoCardActions memo={memo} user={user} />}
      </CardFooterActions>
    </>
  )
}

export default MemoFooterActions
