import React, { useCallback, ReactNode, Ref } from 'react';
import { Image } from '../../theme/NewIcons';
import { Colors } from '../../theme/constants';
import { UnstyledButton } from '../../composing/CSButton';
import { css, cx } from '@linaria/core';
type Props = {
  disabled?: boolean;
  onFile: (file: any) => void;
  multiple?: boolean;
  children?: ReactNode;
  className?: string;
  isPost?: boolean;
};

const UploadFileButton = ({
  disabled,
  onFile,
  multiple,
  children,
  className
}: Props, ref: Ref<HTMLButtonElement>) => {
  const onClick = useCallback(() => {
    if (disabled) return;
    let input = document.createElement('input');
    input.type = 'file'; // .mp4's are disabled, to enable: '.jpg,.jpeg,.png,.gif,.mp4'

    input.accept = '.jpg,.jpeg,.png,.gif';
    input.multiple = Boolean(multiple); // @ts-ignore

    input.onchange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const files = Array.from(e.target.files).filter(file => !!file);
        onFile(files);
      }
    };

    input.click();
  }, [disabled, multiple, onFile]);
  return <UnstyledButton ref={ref} className={cx(buttonClass, className)} onClick={onClick} disabled={disabled}>
      {!children && <Image color={disabled ? Colors.TextQuartiary : 'currentColor'} size={1.25} />}
      {children}
    </UnstyledButton>;
};

export default React.forwardRef(UploadFileButton);
const buttonClass = "b13kzyhl";

require("../../../.linaria-cache/packages/oxcart/src/scopes/text-editor/UploadFileButton.linaria.module.css");