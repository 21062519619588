import { track } from '../mixpanel'
import { GroupChannel, UserMessage, FileMessage } from 'sendbird'
import { isFileMessage, isUserMessage } from 'src/scopes/chat/types'
import { urlRegex } from 'src/scopes/posts/utils'

export function trackChatMessageSent(
  channel: GroupChannel,
  message: UserMessage | FileMessage,
  mentions?: { mentioned_assets: string[]; mentioned_users: string[] }
) {
  track('Chat Message Sent V2', {
    channel_member_count: channel.members.length,
    channel_owner_uuid: channel.creator?.userId,
    channel_type: channel.customType,
    channel_url: channel.url,
    includes_image: isFileMessage(message) && message.type.includes('image'),
    includes_link: isUserMessage(message) && Boolean(message.message.match(urlRegex)?.length),
    text_length: isUserMessage(message) ? message.message.length : 0,
    mentioned_asset_count: mentions ? mentions.mentioned_assets.length : 0,
    mentioned_assets: mentions ? mentions.mentioned_assets : [],
    mentioned_user_count: mentions ? mentions.mentioned_users.length : 0,
    mentioned_users: mentions ? mentions.mentioned_users : []
  })
}
