import { FeedItem } from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { isLoggedIn } from '@commonstock/common/src/auth'
import React, { MouseEvent, useCallback, useMemo } from 'react'
import { CardIcon } from 'src/components/styles'
import { IconButton } from 'src/composing/CSButton'
import { TextCaptionEmphasis } from 'src/composing/CSText'
import { SpacerH } from 'src/composing/Spacing'
import { Env } from 'src/config'
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants'
import { useEphemeralModal, useModal } from 'src/scopes/modal/Modal'
import { Routes } from 'src/scopes/nav/constants'
import EditPost from 'src/scopes/posts/EditPost'
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic'
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal'
import { flexCentered } from 'src/theme/AtomicClasses'
import Tooltip from '../../components/Tooltip'
import { Repost } from '../../theme/NewIcons'

type Props = {
  tradeGroup?: boolean
  trade?: boolean
  memo?: boolean
  post?: boolean
  uuid: string
  disabled?: boolean
  cardLayout?: CardLayoutTypes
  prependItem?: (feedItem: FeedItem) => void
  username?: string
  user: HydratedUserProfile
}
function RepostButton({
  tradeGroup,
  trade,
  memo,
  post,
  disabled,
  cardLayout,
  uuid,
  prependItem,
  username,
  user
}: Props) {
  const modal = useEphemeralModal()
  const loggedOutModal = useLoggedOutModal(`Share`)
  const { actions } = useModal()

  const isDev = process.env.CS_ENV === Env.Dev
  const isStaging = process.env.CS_ENV === Env.Staging
  const origin = isStaging
    ? `https://staging.commonstock.dev`
    : isDev
    ? `https://commonstock.dev`
    : window.location.origin

  const tip = useMemo(() => {
    if (disabled) return 'Repost disabled'
    if (tradeGroup) return 'Repost trades'
    if (trade) return 'Repost trade'
    if (memo) return 'Repost memo'
    if (post) return 'Repost post'
    return ''
  }, [disabled, tradeGroup, trade, memo, post])

  const onFinish = useCallback(
    item => {
      if (prependItem) {
        prependItem(item)
      }
      actions.closeAllModals()
    },
    [actions, prependItem]
  )

  const onClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      if (!isLoggedIn()) {
        loggedOutModal('Join', <JoinModalPublic />)
        return
      }
      const repostedLink = trade
        ? Routes.tradeDetail(uuid)
        : memo
        ? Routes.memoDetail(uuid)
        : tradeGroup
        ? Routes.tradeGroupDetail(uuid)
        : post
        ? Routes.postDetail(uuid)
        : ''

      modal(
        <EditPost
          {...(prependItem
            ? {
                onFinish
              }
            : {})}
          initialText={`\n${origin}${repostedLink}${username ? ` by @${username} ` : ''}`}
          initialMentions={{ user_mentions: [user] }}
          shouldFocusStart
          repost
        />
      )
    },
    [loggedOutModal, memo, modal, onFinish, origin, post, prependItem, trade, tradeGroup, user, username, uuid]
  )

  return (
    <CardIcon>
      <Tooltip tip={tip}>
        <IconButton
          className={flexCentered}
          onClick={disabled ? undefined : onClick}
          disabled={disabled}
          aria-label={'Repost content'}
        >
          <Repost size={1.25} />
          {isUpvoteLayout(cardLayout) && (
            <>
              <SpacerH rem={0.5} />
              <TextCaptionEmphasis>Repost</TextCaptionEmphasis>
            </>
          )}
        </IconButton>
      </Tooltip>
    </CardIcon>
  )
}

export default RepostButton
