import { createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { TradeFeedItem, TradeFeedOutput, TradeGroupFeedItem } from './feed'

const tradeFeedOutputPagerOptions: PagerOptions<TradeFeedOutput, DefaultQueryParams> = {
  getCursor: (page, resource) => {
    let privacy = resource?.params.query.privacy
    let list = resource?.success?.payload.items
    let last = list && list[list.length - 1]
    // @NOTE: All trades except for portfolio page, should have privacy 'on'
    return { query: { limit: 10, last_order: last?.uuid, privacy: privacy || 'on' } }
  },
  isTerminal: ({ success }) => !!(success && success.payload.items.length === 0)
}

type DefaultQueryParams = {
  query: {
    // @TODO: Check this schema with BE
    last_order?: string
    limit?: number
    privacy: 'on' | 'off'
    order_side?: string
  }
}

export type UserTradeParams = DefaultQueryParams & {
  meta: {
    user_uuid: string
  }
}

export const useGetUserTrades = createUsePagedFetch<TradeFeedOutput, UserTradeParams>(
  {
    key: 'user-trades',
    path: ({ meta: { user_uuid } }) => `/feed/v3/user-trades/${user_uuid}`
  },
  tradeFeedOutputPagerOptions
)

export type MyAssetTradesParams = DefaultQueryParams & {
  meta: {
    user_uuid: string
    symbol: string
    type: string
  }
}

export const useGetMyAssetTrades = createUsePagedFetch<TradeFeedOutput, MyAssetTradesParams>(
  {
    key: 'user-asset-trades',
    path: ({ meta: { user_uuid, symbol, type } }) => `/feed/v3/user-trades/${user_uuid}/${symbol}:${type}`
  },
  tradeFeedOutputPagerOptions
)

export type AssetTradesParams = DefaultQueryParams & {
  meta: {
    symbol: string
    type: string
  }
}

export const useGetAssetTrades = createUsePagedFetch<TradeFeedOutput, AssetTradesParams>(
  {
    key: 'asset-trades',
    path: ({ meta: { symbol, type } }) => `/feed/v3/asset-trades/${symbol}:${type}`
  },
  tradeFeedOutputPagerOptions
)

export const BASE_TRADE_PATH = '/feed/v3/trade/'
type TradeParams = { meta: { tradeId: string } }
export const useGetTrade = createUseFetch<TradeFeedItem, TradeParams>({
  key: 'trade',
  path: ({ meta: { tradeId } }) => `${BASE_TRADE_PATH}${tradeId}`
})

export const BASE_TRADE_GROUP_PATH = '/feed/v3/trade/'
type tradeGroupParams = { meta: { tradeGroupId: string } }
export const useGettradeGroup = createUseFetch<TradeGroupFeedItem, tradeGroupParams>({
  key: 'trade-group',
  path: ({ meta: { tradeGroupId } }) => `/trade-group/v3/${tradeGroupId}`
})
