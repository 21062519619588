import React, { useCallback, useEffect, useState } from 'react';
import { UserProfile, useUnBlockProfile, useMuteUserAction, useUnmuteUserAction, BadgeType } from '@commonstock/common/src/api/profile';
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys';
import { DangerButton, SecondaryButton } from '../../composing/CSButton';
import { unexpectedError } from '../../dev/sentry';
import { useUser } from '../auth/AuthContext';
import { MenuItem } from '../../components/styles';
import { useEphemeralModal } from '../modal/Modal';
import ShareModal from '../share/ShareModal';
import { css } from '@linaria/core';
import BanModal, { BanAction } from './BanModal';
import { ShareType } from '../../../../common/src/api/link';
import BadgeModal, { BadgeAction } from './BadgeModal';
import dynamic from 'next/dynamic';
const Block = dynamic(() => import('./Block'));
const RemoveFollower = dynamic(() => import('./RemoveFollower'));

function useClickHandler(profile: UserProfile) {
  const modal = useEphemeralModal();
  const postUnBlock = useUnBlockProfile();
  const refetchDependents = useRefetchByKeys('blocked-users', 'user-profile', 'profile-username');
  const onClick = useCallback(async (e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLSpanElement>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (profile.blocked) await postUnBlock({
      meta: profile
    });else modal(<Block userUuid={profile.user_uuid} />);
    refetchDependents();
  }, [modal, postUnBlock, profile, refetchDependents]);
  return onClick;
}

function BlockProfileItem({
  profile
}: {
  profile: UserProfile;
}) {
  let onClick = useClickHandler(profile);
  return <MenuItem onClick={onClick} role="button" tabIndex={0} onKeyDown={onClick}>
      {profile.blocked ? 'Unblock' : 'Block'}
    </MenuItem>;
}

function BlockedProfileButton({
  profile
}: {
  profile: UserProfile;
}) {
  let onClick = useClickHandler(profile);
  return <DangerButton className={blockedButton} onClick={onClick}>
      Unblock
    </DangerButton>;
}

function RemoveFollowerButton({
  profile
}: {
  profile: UserProfile;
}) {
  const modal = useEphemeralModal();
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    modal(<RemoveFollower userUuid={profile.user_uuid} />);
  }, [modal, profile.user_uuid]);
  return <SecondaryButton small onClick={handleClick}>
      Remove
    </SecondaryButton>;
}

function MuteToggleItem({
  profile
}: {
  profile: Partial<UserProfile> & {
    uuid?: string;
  };
}) {
  const muteUser = useMuteUserAction();
  const unmuteUser = useUnmuteUserAction();
  const refetchDependents = useRefetchByKeys('my-profile', 'get-feed', 'user-profile', 'profile-username');
  const [user] = useUser();
  const [isMuted, setIsMuted] = useState(false);
  useEffect(() => {
    if (user) {
      const uuid = profile.user_uuid || profile.uuid;
      setIsMuted(user.muted_users.includes((uuid as string)));
    }
  }, [profile, user]);

  const onClick = async (e?: any) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const uuid = profile.user_uuid || profile.uuid;

    if (uuid) {
      try {
        isMuted ? await unmuteUser({
          meta: {
            user_uuid: uuid
          }
        }) : await muteUser({
          meta: {
            user_uuid: uuid
          }
        });
        setIsMuted(prev => !prev);
        refetchDependents();
      } catch (err) {
        unexpectedError(err);
      }
    }
  };

  return <MenuItem onClick={onClick} onKeyDown={onClick}>
      {isMuted ? 'Unmute' : 'Mute'}
    </MenuItem>;
}

function ShareProfileItem({
  profile
}: {
  profile: UserProfile;
}) {
  const modal = useEphemeralModal();
  const handleClick = useCallback(() => modal(<ShareModal type={ShareType.PROFILE} uuid={profile.user_uuid} />), [modal, profile.user_uuid]);
  return <MenuItem onClick={handleClick} role="button" onKeyDown={handleClick}>
      Share
    </MenuItem>;
}

function BanItems({
  profile
}: {
  profile: UserProfile;
}) {
  const modal = useEphemeralModal();

  const onClick = (action: BanAction) => {
    modal(<BanModal profile={profile} action={action} />);
  }; // @NOTE: We display both options since there is no way to know if a user is
  // banned or not.


  return <>
      <MenuItem isModerator onClick={() => onClick(BanAction.Unban)}>
        Unban
      </MenuItem>
      <MenuItem isModerator onClick={() => onClick(BanAction.Ban)}>
        Ban
      </MenuItem>
    </>;
}

function BadgeUserItems({
  profile
}: {
  profile: UserProfile;
}) {
  const modal = useEphemeralModal();
  const isVerified = !!profile.badges?.find(b => b.type === BadgeType.VERIFIED);
  const isSharedBrokerage = !!profile.badges?.find(b => b.type === BadgeType.SHARED_BROKERAGE);
  const isTitan = !!profile.badges?.find(b => b.type === BadgeType.TITAN);

  const onClick = ({
    actionType,
    badgeType
  }: {
    actionType: BadgeAction;
    badgeType: BadgeType;
  }) => {
    modal(<BadgeModal actionType={actionType} badgeType={badgeType} username={profile.username} />);
  };

  return <>
      <MenuItem isModerator onClick={() => onClick({
      actionType: isVerified ? BadgeAction.REMOVE_BADGE : BadgeAction.ADD_BADGE,
      badgeType: BadgeType.VERIFIED
    })}>
        {isVerified ? `Remove Verified` : `Add Verified`}
      </MenuItem>
      <MenuItem isModerator onClick={() => onClick({
      actionType: isSharedBrokerage ? BadgeAction.REMOVE_BADGE : BadgeAction.ADD_BADGE,
      badgeType: BadgeType.SHARED_BROKERAGE
    })}>
        {isSharedBrokerage ? `Remove Broker Badge` : `Add Broker Badge`}
      </MenuItem>
      <MenuItem isModerator onClick={() => onClick({
      actionType: isTitan ? BadgeAction.REMOVE_BADGE : BadgeAction.ADD_BADGE,
      badgeType: BadgeType.TITAN
    })}>
        {isTitan ? `Remove Titan` : `Add Titan`}
      </MenuItem>
    </>;
}

export { BlockProfileItem, BlockedProfileButton, MuteToggleItem, RemoveFollowerButton, ShareProfileItem, BanItems, BadgeUserItems };
const blockedButton = "b1gtsgfb";

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/ProfileButtons.linaria.module.css");