import { CommentSummary } from '@commonstock/common/src/api/comment'
import {
  FeedItemResources,
  MemoResource,
  PostResource,
  TradeFeedItem,
  TradeGroupFeedItem,
  TradeGroupResource,
  TradeResource
} from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { AssetMention } from '@commonstock/common/src/types/mentions'

type DestructedTrade = {
  trade?: TradeResource
  tradeGroup?: TradeGroupResource
  user?: HydratedUserProfile
  resources: FeedItemResources
  assets: { [key: string]: AssetMention }
  tradeAsset?: AssetMention
  linkedPosts: PostResource[]
  linkedMemos: MemoResource[]
  comments?: CommentSummary
}
export function destructTradeFeedItem(tradeFeedItem: TradeFeedItem | TradeGroupFeedItem): DestructedTrade {
  const { resources } = tradeFeedItem
  let trade: TradeResource | undefined = resources.trades[tradeFeedItem.uuid]
  let tradeGroup: TradeGroupResource | undefined = resources.trade_groups[tradeFeedItem.uuid]
  let user: HydratedUserProfile | undefined = resources.users[tradeFeedItem.user_uuid]

  // BE return a wrong object when it fails to grab it from DB, so we need to check for the current uuid of them
  trade = tradeFeedItem.uuid === trade?.uuid ? trade : undefined
  tradeGroup = tradeFeedItem.uuid === tradeGroup?.uuid ? tradeGroup : undefined
  user = tradeFeedItem.user_uuid === user?.uuid ? user : undefined

  const assets = resources.assets
  const tradeAsset = trade ? resources.assets[trade.asset_identifier] : undefined

  const linkedPosts: PostResource[] =
    (trade || tradeGroup)?.related_posts?.map(id => resources.posts[id] || null).filter(Boolean) || []
  const linkedMemos: MemoResource[] =
    (trade || tradeGroup)?.related_memos?.map(id => resources.memos[id] || null).filter(Boolean) || []

  const comments = (trade || tradeGroup)?.comments

  return { trade, tradeGroup, user, resources, assets, tradeAsset, linkedPosts, linkedMemos, comments }
}
