import React from 'react';
import { css } from '@linaria/core';
import { FlexColumn, FlexRow } from '../../composing/Flex';
import { SkeletonBlock, SkeletonCircle } from '../../components/Skeleton';
import { SpacerV, SpacerH } from '../../composing/Spacing';
import { Colors } from '../../theme/constants';
export function PostListItemSkeleton() {
  return <FlexRow className={containerClass}>
      <FlexColumn>
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125} />
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125} />
        <FlexRow alignCenter>
          <SkeletonCircle h={0.75} />
          <SpacerH rem={0.375} />
          <SkeletonBlock h={1} w={1.75} />
          <SpacerH rem={0.375} />
          <SkeletonCircle h={0.25} />
          <SpacerH rem={0.375} />
          <SkeletonBlock h={1} w={1.75} />
          <SpacerH rem={0.375} />
          <SkeletonCircle h={0.25} />
          <SpacerH rem={0.375} />
          <SkeletonBlock h={1} w={1.75} />
          <SpacerH rem={0.375} />
          <SkeletonCircle h={0.25} />
          <SpacerH rem={0.375} />
          <SkeletonBlock h={1} w={1.75} />
        </FlexRow>
      </FlexColumn>
      <SkeletonBlock w={4} h={3.875} />
    </FlexRow>;
}
const containerClass = "c3wz567";

require("../../../.linaria-cache/packages/oxcart/src/scopes/posts/Posts.skeleton.linaria.module.css");