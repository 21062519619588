import React from 'react';
import { useGetPost } from '@commonstock/common/src/api/post';
import { PostFeedItem } from '@commonstock/common/src/api/feed';
import { useAuth } from '../auth/AuthContext';
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment';
import { DetailCard, PaneColumn, PanelGrid } from '../../components/styles';
import { BrandSpinner } from '../../components/Spinner';
import { SpacerV, Spacing } from '../../composing/Spacing';
import CommentItem from '../comments/CommentItem';
import { css, cx } from '@linaria/core';
import RootComments from '../comments/RootComments';
import { PostContent } from './PostCard';
import PostFooterActions from './PostFooterActions';
import { useRouter } from 'next/router';
import { useGetLikes } from '../likes/likeHooks';
import NotFound from '../../components/NotFound';
import LikeList from '../../components/LikeList';
import { LikeTypes } from '../../../../common/src/api/like';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { useFlags } from 'src/utils/useFlags';
import AuthorBlock from 'src/scopes/profile/AuthorBlock';
import { TextDisplayMedium } from 'src/composing/CSText';
import RelatedBy, { RelatedContentTypes } from 'src/scopes/discover/RelatedBy';
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants';
import PostColumnActions from 'src/scopes/posts/PostColumnActions';
import { FlexRow } from 'src/composing/Flex';
import { scrollY } from 'src/theme/AtomicClasses';
type Params = {
  postId: string;
  commentId?: string;
};
type Props = {
  isModal?: boolean;
  postFeedItem?: PostFeedItem;
  uuid?: string;
  cardLayout?: CardLayoutTypes;
};

function PostDetail({
  isModal,
  postFeedItem,
  uuid,
  cardLayout
}: Props) {
  const {
    webRelatedContent
  } = useFlags();
  const router = useRouter();
  const {
    commentId,
    postId
  } = (router.query as Params);
  const {
    isModerator
  } = useAuth();
  const postUuid = uuid || postId || postFeedItem?.uuid || '';
  const [fetchPost, fail] = useGetPost({
    meta: {
      uuid: postUuid
    }
  }, {
    paused: postFeedItem && !isLoggedIn() || !postUuid
  });
  const [fetchComment, failComment] = useGetComment({
    meta: {
      uuid: commentId || ''
    }
  }, {
    paused: !commentId
  });
  postFeedItem = fetchPost || postFeedItem;
  const rootUserUuid = postFeedItem?.user_uuid || '';

  if (!isModal && (fail || failComment)) {
    return <PanelGrid>
        <PaneColumn>
          <NotFound />
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>;
  }

  if (isModal && fail) {
    return <DetailCard className={cx(isModal && 'modal')}>
        <NotFound />
      </DetailCard>;
  }

  if (!postFeedItem || commentId && !fetchComment) {
    if (isModal) {
      return <DetailCard className={cx(isModal && 'modal', 'loading')}>
          <BrandSpinner />
        </DetailCard>;
    }

    return <PanelGrid>
        <PaneColumn>
          <DetailCard className={cx(isModal && 'modal', 'loading')}>
            <BrandSpinner />
          </DetailCard>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>;
  }

  if (isModerator && fetchComment?.archived_at) {
    return <PanelGrid>
        <PaneColumn>
          <Spacing padding={[0, 1, 1]}>
            <DetailCard>
              <CommentItem comment={fetchComment} rootType={ParentTypes.POST} rootUuid={postUuid} rootUserUuid={rootUserUuid} />
            </DetailCard>
          </Spacing>
        </PaneColumn>
        <PaneColumn />
      </PanelGrid>;
  }

  if (!isModal) {
    return <PanelGrid>
        <PaneColumn>
          <PostPageContent isModal={false} postFeedItem={postFeedItem} postUuid={postUuid} rootUserUuid={rootUserUuid} />
        </PaneColumn>
        <PaneColumn>
          {webRelatedContent && <>
              <AuthorBlock feedItem={postFeedItem} />
              <TextDisplayMedium>Related</TextDisplayMedium>
              <SpacerV />
              <RelatedBy relatedBy={RelatedContentTypes.ByAuthor} feedItem={postFeedItem} />
              <RelatedBy relatedBy={RelatedContentTypes.ByAsset} feedItem={postFeedItem} />
              <RelatedBy relatedBy={RelatedContentTypes.ByTrending} feedItem={postFeedItem} />
            </>}
        </PaneColumn>
      </PanelGrid>;
  }

  return <PostPageContent isModal={isModal} postFeedItem={postFeedItem} postUuid={postUuid} rootUserUuid={rootUserUuid} className={cx(isModal && 'modal', isModal && scrollY)} cardLayout={cardLayout} />;
}

export default PostDetail;
type PostMainContentProps = {
  isModal?: boolean;
  postFeedItem: PostFeedItem;
  postUuid: string;
  rootUserUuid: string;
  className?: string;
  cardLayout?: CardLayoutTypes;
};

function PostPageContent({
  isModal,
  postFeedItem,
  postUuid,
  rootUserUuid,
  className,
  cardLayout
}: PostMainContentProps) {
  const likes = useGetLikes(postUuid, LikeTypes.POST, postFeedItem);
  const initialComments = postFeedItem?.resources.posts[postFeedItem.uuid]?.comments;
  return <DetailCard className={cx(className, isUpvoteLayout(cardLayout) && 'hasColumnActions')}>
      {isUpvoteLayout(cardLayout) ? <FlexRow>
          <PostColumnActions postFeedItem={postFeedItem} cardLayout={cardLayout} />
          <div className={mainContentPadding}>
            <PostContent postFeedItem={postFeedItem} showFollowerAssets cardLayout={cardLayout} detail />
            <PostFooterActions postFeedItem={postFeedItem} cardLayout={cardLayout} detail likes={likes} />
            <RootComments initialComments={initialComments} uuid={postUuid} type={ParentTypes.POST} isModal={!!isModal} rootUserUuid={rootUserUuid} cardLayout={cardLayout} />
          </div>
        </FlexRow> : <>
          <PostContent postFeedItem={postFeedItem} detail />
          <PostFooterActions postFeedItem={postFeedItem} cardLayout={cardLayout} detail />
          <LikeList likes={likes} uuid={postUuid} />
          <RootComments initialComments={initialComments} uuid={postUuid} type={ParentTypes.MEMO_PLUS} isModal={!!isModal} rootUserUuid={rootUserUuid} />
        </>}
    </DetailCard>;
}

const mainContentPadding = "mk7xq";

require("../../../.linaria-cache/packages/oxcart/src/scopes/posts/PostDetail.linaria.module.css");