import React from 'react';
import { css, cx } from '@linaria/core';
import { FollowStatus, UserProfile } from '@commonstock/common/src/api/profile';
import { SecondaryButton } from '../../composing/CSButton';
import { TextDisplayLarge, TextDisplaySmall, TextDisplayMedium, TextSubhead, TextCaption } from '../../composing/CSText';
import { Colors, FontWeights } from '../../theme/constants';
import { Globe, Hide } from '../../theme/NewIcons';
import { Twitter } from '../../theme/Logos';
import { SpacerV, SpacerH } from '../../composing/Spacing';
import CSLink from '../../composing/CSLink';
import useTwitterAuthCallback from '../auth/useTwitterAuthCallback';
import { flexCentered, gradientText } from '../../theme/AtomicClasses';
import { FlexColumn, FlexRow } from '../../composing/Flex';
import InfoButton, { InfoType } from '../../components/InfoButton';
import { formatLargeMoney } from '@commonstock/common/src/utils/format';
import { ContentCard } from '../../components/styles';
import { bioMarkdown, Formatter } from '../text-editor/csMarkdown';
import FollowStats from './FollowStats';
import UsersYouKnow from './UsersYouKnow';
import { ProfileAvatar } from '../../components/Avatar';
import { FollowStatsSkeleton, ProfileBlockSkeleton } from './Profile.skeleton';
import HiddenProfile from './HiddenProfile';
import { SkeletonWrapper } from '../../components/SuspenseSkeleton';
import Badges from 'src/components/Badges';
import { isLoggedIn } from '@commonstock/common/src/auth';
import FollowProfile from './FollowProfile';
import { Routes } from '../nav/constants';
type Props = {
  isMe: boolean;
  profile?: UserProfile;
  pending: boolean;
  hero?: boolean;
  followButton?: boolean;
};

function ProfileBlock({
  isMe,
  profile,
  pending,
  hero,
  followButton
}: Props) {
  const isCSTeam = profile?.username === 'commonstock';
  const {
    blocked,
    blocked_me,
    private: isPrivate,
    follow_status
  } = profile || {};
  const isVisible = isMe || (!isPrivate || follow_status === FollowStatus.FOLLOWED) && !blocked && !blocked_me;
  const NameText = hero ? TextDisplayLarge : TextDisplaySmall;
  const FollowerAssetText = hero ? TextDisplayMedium : TextDisplaySmall;
  return <SkeletonWrapper pending={!profile} skeleton={<ProfileBlockSkeleton isMe={isMe} hero={hero} followButton={followButton} />}>
      {profile && <ContentCard>
          <FlexRow alignStart>
            <ProfileAvatar size={3.375} avatar={profile.picture_raw} name={profile.name} username={profile.username} />
            <SpacerH rem={0.75} />
            <FlexColumn>
              <FlexRow alignCenter>
                <CSLink href={Routes.usernameProfile(profile.username)} unstyled>
                  <NameText className={cx(nameClass, !hero && boldClass)}>{profile.name}</NameText>
                </CSLink>
                <SpacerH rem={0.5} />
                {isPrivate && <>
                    <InfoButton type={InfoType.PRIVATE_PROFILE} />
                    <SpacerH rem={0.5} />
                  </>}
                {profile.badges && <Badges badges={profile.badges} large={hero} />}
              </FlexRow>
              <SpacerV rem={0.25} />
              <FlexRow alignCenter>
                <FollowerAssetText className={cx(gradientText, hero && normalWeightClass)}>
                  {isCSTeam ? null : !profile.follower_assets ? <Hide gradient size={1.25} className={verticalAlign} /> : formatLargeMoney(profile.follower_assets || 0)}{' '}
                  follower assets
                </FollowerAssetText>
                <SpacerH rem={0.5} />
                <InfoButton type={InfoType.PROFILE} />
              </FlexRow>
            </FlexColumn>
            {!isMe && followButton && <FollowProfile profile={profile} />}
          </FlexRow>
          <SpacerV />

          {!isVisible ? <>
              {!isCSTeam && <FollowStats profile={profile} isMe={isMe} />}
              <HiddenProfile profile={profile} />
            </> : <>
              <ProfileBio profile={profile} hero={hero} />
              <ExternalLinks profile={profile} isMe={isMe} />
              {isCSTeam ? null : pending ? <FollowStatsSkeleton /> : <FollowStats profile={profile} isMe={isMe} />}
              {isLoggedIn() && !isCSTeam && !isMe && <UsersYouKnow profile={profile} />}
            </>}
        </ContentCard>}
    </SkeletonWrapper>;
}

function ProfileBio({
  profile,
  hero
}: {
  profile: UserProfile;
  hero?: boolean;
}) {
  return <>
      <TextSubhead color={Colors.TextPrimary}>
        <Formatter text={profile.bio} processor={bioMarkdown} clampLine={!hero ? 2 : undefined} />
      </TextSubhead>
      <SpacerV />
    </>;
}

function ExternalLinks({
  isMe,
  profile
}: {
  isMe?: boolean;
  profile: UserProfile;
}) {
  const {
    twitter_username,
    website
  } = profile;
  const authTwitter = useTwitterAuthCallback();

  const onClick = () => authTwitter(`${window.location.origin}/twitter/follow`);

  if (!twitter_username && !website && !isMe) return null;
  return <>
      <FlexRow alignCenter h={1.5}>
        {isMe && !twitter_username ? <SecondaryButton className={twitterClass} onClick={onClick}>
            <Twitter />
            Link Twitter
          </SecondaryButton> : twitter_username ? <CSLink external className={flexCentered} href={`https://twitter.com/${twitter_username}`}>
            <Twitter size={1.25} color={Colors.ActionsTwitterDefaut} />
            <SpacerH rem={0.25} />
            <TextCaption color={Colors.ActionsTwitterDefaut}>@{twitter_username}</TextCaption>
          </CSLink> : null}

        {(!!twitter_username || isMe) && !!website && <SpacerH rem={1.5} />}

        {!!website && <CSLink external className={cx(flexCentered, websiteClass)} href={`https://${website}`}>
            <Globe size={1.25} color={Colors.TextLinks} />
            <SpacerH rem={0.5} />
            <TextCaption>{website}</TextCaption>
          </CSLink>}
      </FlexRow>
      {
      /* Only shows next follow suggestion if not my profile */
    }
      <SpacerV />
    </>;
}

export default ProfileBlock;
const normalWeightClass = "nsslktv";
const boldClass = "b1cawz3w";
const nameClass = "nuntfsa";
const websiteClass = "wjxlvk8";
const twitterClass = "t4xyfbn";
const verticalAlign = "vds753y";

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/ProfileBlock.linaria.module.css");