import React, { useCallback, useRef, useState, ReactNode, useMemo, useContext, useLayoutEffect, useEffect, useImperativeHandle, Ref } from 'react';
import { renderToString } from 'react-dom/server';
import { chatMarkdown, memoMarkdown, regexEmoji, wrapEmojiCharacters, getEmojiImageElement, Formatter, commentsMarkdown, postsMarkdown, isMarkdownParseableRegex } from './csMarkdown';
import useDragFile from '../../utils/useDragFile';
import { useCreateMediaLink } from '../trade/tradeHooks';
import { Colors } from '../../theme/constants';
import { cx, css } from '@linaria/core';
import MentionsContext from './Mentions';
import { useKeyDown } from '@commonstock/common/src/utils/useKeyPress';
import debounce from 'lodash.debounce';
import { addMentionToElement, maybeAutoList, convertHtmlToMarkdown, getMarkdownFromCopiedText, getRangeInTarget, getRangeOrEnd, mentionDetect, preHydrateText, resetSelectionRange, maybeUnwrapLinkedMention, MentionDetected, useUpdateLinkedMentions, modifySelection, getMarkdownFromPastedText, removeLink, wrapPostLinks, removeStyleAndFormattingTags, autoWrapText, removeSpanProps } from './utils';
import { ClientProvider } from '@commonstock/client/src/react/context';
import client from '../../client';
import { MentionAttachments } from '@commonstock/common/src/types/mentions';
import unified from 'unified';
import { captureException } from '../../dev/sentry';
import { useEphemeralModal } from '../modal/Modal';
import { openLinkModal } from './Toolbar';
import config from '../../config';
import { Gif, isGif } from '@commonstock/common/src/api/gifs';
import { MEDIA_SERVICE_URL, MEDIA_CDN_URL } from '../../utils/mediaUrls';
import ImageSvg from '../../assets/image.svg';
import { baseTextStyle } from '../../components/styles';
import { TextEditorValue, Helper, TextEditorContext, Listener, HelperType, HelperPayload, HelperOwnedKeys } from './TextEditorContext';
import { HydratedLinkItem, useGetPostHydratedLink } from '@commonstock/common/src/api/post';
import { getLinks, urlRegex } from '../posts/utils';
import { HTMLDivProps } from '../../utils/types'; // this is a global configuration, we do it here for findability later

typeof document !== 'undefined' && document.execCommand?.('defaultParagraphSeparator', false, 'p');
type Props = {
  className?: string;
  initialText?: string;
  initialMentions?: MentionAttachments;
  children: ReactNode;
  placeholder?: string;
  persistKey?: string | false;
  dropFilesCallback?: false | ((f: Array<File>) => void);
  insertFilesCallback?: (f: Array<File | string | Gif>) => void;
  markdownProcessor?: unified.Processor<unified.Settings>;
  editMemo?: boolean;
  linkPreview?: HydratedLinkItem | false;
  setLinkPreview?: (value: HydratedLinkItem | false | undefined) => void;
  setLinkPreviewPending?: (value: boolean) => void;
  shouldRemoveLink?: boolean;
  shouldFocusStart?: boolean;
};

function _Provider({
  className,
  initialText,
  initialMentions = {},
  children,
  placeholder = '',
  persistKey,
  dropFilesCallback,
  insertFilesCallback,
  markdownProcessor,
  editMemo,
  linkPreview,
  setLinkPreview,
  setLinkPreviewPending,
  shouldRemoveLink,
  shouldFocusStart
}: Props, ref: Ref<TextEditorValue>) {
  markdownProcessor = markdownProcessor ? markdownProcessor : commentsMarkdown;
  let plaintext = ![memoMarkdown, commentsMarkdown].includes(markdownProcessor);
  let targetRef = useRef<HTMLDivElement>(null);
  let modal = useEphemeralModal();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  let focus = useCallback((toStart?: boolean | undefined) => {
    resetSelectionRange(getRangeOrEnd(targetRef.current, toStart, toStart));
  }, []);
  let [mentionData, setMentionData] = useState<MentionDetected | null>(null);
  const [hasInitialized, setHasInitialized] = useState(false);
  let listenersRef = useRef<Set<Listener>>(new Set());
  const listen = useCallback((cb: (el: HTMLDivElement) => void) => {
    listenersRef.current.add(cb);
    if (targetRef.current) cb(targetRef.current);
    return () => {
      listenersRef.current.delete(cb);
    };
  }, []);
  const getMarkdown = useCallback(() => {
    return convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext
    });
  }, [plaintext]);
  const getPlaintext = useCallback(() => {
    return convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext,
      returnPlaintext: true
    });
  }, [plaintext]);
  const getPlaintextLength = useCallback(() => {
    const text = convertHtmlToMarkdown({
      target: targetRef.current,
      plaintext: !!plaintext,
      returnPlaintext: true
    });
    let urlLength = (0 as number);
    ((text || '').match(urlRegex) || []).forEach(url => {
      urlLength = urlLength + (url.startsWith(' ') || url.startsWith('\t') || url.startsWith('\n') ? 25 : 24);
    }); // Links should be counted as 24 characters. Since this regex includes the space before, it can be 25.

    const textWithoutUrls = text.replace(urlRegex, '').replace(/\n\n/g, 'x').length;
    return textWithoutUrls + urlLength;
  }, [plaintext]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [mentionedWithAutoSpace, setMentionedWithAutoSpace] = useState<null | string>(null);
  const getHydratedLinks = useGetPostHydratedLink();
  let checkLink = useCallback(() => {
    if (setLinkPreview && targetRef.current) {
      const hydrateLink = async () => {
        const links = getLinks(targetRef.current?.innerText);
        const lastLink = links ? links[links?.length - 1] : '';

        if (lastLink) {
          const response = await getHydratedLinks({
            json: {
              urls: [lastLink]
            }
          });

          if (response.success?.payload && Object.keys(response.success.payload).length > 0 && Object.values(response.success.payload)[0].title && targetRef.current) {
            if (shouldRemoveLink) {
              targetRef.current.innerHTML = removeLink(targetRef, targetRef.current?.innerHTML || '', lastLink, focus);
            }

            setLinkPreview(Object.values(response.success?.payload)[0]);
          }

          setLinkPreviewPending && setLinkPreviewPending(false);
        }
      };

      const links = getLinks(targetRef.current?.innerText);
      const lastLink = links ? links[links?.length - 1] : '';

      if (!linkPreview || linkPreview.url !== lastLink) {
        if (timer) {
          clearTimeout(timer);
        }

        if (linkPreview === false) {
          if (links?.length === 0) {
            setLinkPreview(undefined);
          }

          return;
        }

        if (lastLink) {
          setLinkPreviewPending && setLinkPreviewPending(true);
        }

        setTimer(setTimeout(() => {
          hydrateLink();
        }, 1000));
      }
    }

    return () => {};
  }, [focus, getHydratedLinks, linkPreview, setLinkPreview, setLinkPreviewPending, shouldRemoveLink, timer]); // anytime something changes, we need to check for mentions and set helper state accordingly

  let checkMention = useCallback((e?: React.FormEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!targetRef.current) return;
    setIsEmpty(targetRef.current.innerText.trim() === '' && !targetRef.current?.querySelector('ul, ol, img'));
    if (!plaintext) maybeAutoList(targetRef.current);
    const nativeEvent = e?.nativeEvent; // @ts-ignore need to discover how to tye this event

    const typedChar = (nativeEvent?.data || '' as string);
    const mentionDetected = mentionDetect(targetRef.current); // @ts-ignore need to discover how to tye this event

    const inputType = (nativeEvent?.inputType || '' as string); // When changing an mention link text, it unwrapps the link if the user remove any character from the current mention
    // Prevent unwrapping from running when Undoing or Redoing
    // @NOTE: This has one remaining bug, when undoing the mention will still be missing 1 character

    if (mentionDetected.range && !inputType?.includes('history')) maybeUnwrapLinkedMention(targetRef.current, mentionDetected.range); // If the user has just mentioned something, and it has auto space added to the end,
    // we adjust some characters to being collapsed to the text on next hit

    if (mentionedWithAutoSpace) {
      const selection = document.getSelection();
      const range = selection?.getRangeAt(0).cloneRange();

      if (selection && range && mentionedWithAutoSpace && typedChar.match(/[,.\])}!?]/g)) {
        range.setStart(range.startContainer, 0);
        const match = range.toString().endsWith(` ${typedChar}`);
        if (!match) return;
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('insertText', false, `${typedChar} `);
      }

      setMentionedWithAutoSpace(null);
    } // Replace inserted emoji by a image since browsers has some alignment issues


    let selection = document.getSelection();

    if (selection && config.flags.emojiToImage && typedChar.match(regexEmoji)) {
      modifySelection(selection, 'extend', 'left', 'character');
      const wrapper = document.createElement('span');
      wrapper.appendChild(getEmojiImageElement(typedChar));
      document.execCommand('insertHtml', false, wrapper.innerHTML);
    }

    setMentionData(mentionDetected.type ? mentionDetected : null);
    if (targetRef.current?.classList.contains('mentioning')) return;
  }, [mentionedWithAutoSpace, plaintext]);
  let checkLiveEdit = useCallback((e?: React.KeyboardEvent<HTMLDivElement>) => {
    if (!targetRef.current) return; // @NOTE this will clear out the div so the placeholder reappears on safari

    if (markdownProcessor === chatMarkdown && targetRef.current && targetRef.current.textContent === '') {
      targetRef.current.innerHTML = '';
    }

    if (!plaintext && (e?.key === '*' || e?.key === '_' || e?.key === ' ') && targetRef.current) {
      const mentionDetected = mentionDetect(targetRef.current);

      if (!mentionDetected.type) {
        autoWrapText(targetRef.current, new RegExp(/_([^\s][^_]+?[^\s])_/, 'gm'), 'italic');
      }

      autoWrapText(targetRef.current, new RegExp(/\*([^\s][^*]+?[^\s])\*/, 'gm'), 'bold');
    }

    requestIdleCallback(() => {
      if (!targetRef.current) return;

      if (plaintext && markdownProcessor === postsMarkdown) {
        checkLink();
        wrapPostLinks(targetRef.current, e?.key);
      } else {
        removeSpanProps(targetRef.current);
      }
    }, {
      timeout: 500
    });
  }, [checkLink, markdownProcessor, plaintext]);
  let onBlur = useCallback(e => {
    const isInputHelperClicked = e.relatedTarget?.closest(`.inputHelper`); // Add timeout to fix Safari click to select, as it doesnt support relatedTarget

    if (!isInputHelperClicked) setTimeout(() => setMentionData(null), 200);
  }, []);
  const {
    createMediaLink
  } = useCreateMediaLink({
    silent: true
  });

  const getImage = (file: File | string | Gif) => {
    const url = file instanceof File ? URL.createObjectURL(file) : isGif(file) ? file.images.original_still.url : file;
    let img = new Image(); // Add CORS policy to image, to be able to get Gif and outter sources

    img.crossOrigin = 'Anonymous';
    img.id = `${Math.random()}`;
    img.src = url;
    img.classList.add('uploading', 'loading');

    function getUploadingPlaceholder() {
      if (img.classList.contains('uploading') === false) return;
      img.classList.remove('loading');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx) {
        try {
          canvas.width = 35 * 16;
          canvas.height = 25 * 16; // Get image scale, based on max height/width

          let scale = Math.min(canvas.width / img.width, canvas.height / img.height);
          scale = scale < 1 ? scale : 1; // Update canvas to final size, based on max height and width

          canvas.width = img.width * scale;
          canvas.height = img.height * scale;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw dark overlay over the image

          ctx.fillStyle = 'rgba(0, 0, 0,0.6)';
          ctx.fillRect(0, 0, canvas.width, canvas.height); // Draw uploading text above th rectangle

          ctx.font = `normal ${16}px Inter`;
          ctx.fillStyle = '#FFF';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText('Uploading image', canvas.width * 0.5, canvas.height * 0.5); // Replace image src with canvas generated image

          const canvasSrc = canvas.toDataURL('image/jpeg');
          const imgEl = (document.getElementById(img.id) as HTMLImageElement | null);
          if (imgEl && imgEl.classList.contains('uploading')) imgEl.src = canvasSrc;
        } catch (error) {
          captureException(error);
        }
      }
    }

    img.onload = getUploadingPlaceholder;
    return img;
  };

  const insertFailedImage = useCallback((img: HTMLImageElement | null, message: string) => {
    if (!img) return;
    img.src = ImageSvg;
    img.classList.remove('loading', 'uploading');

    function insetText() {
      if (img?.classList.contains('failed-image')) return;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (ctx && img) {
        try {
          canvas.width = 150;
          canvas.height = 150;
          ctx.drawImage(img, 0, -10, canvas.width, canvas.height);
          ctx.font = `normal ${16}px Inter`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(message, canvas.width * 0.5, canvas.height - 10);
          const canvasSrc = canvas.toDataURL();
          const imgEl = (document.getElementById(img.id) as HTMLImageElement | null);

          if (imgEl) {
            imgEl.src = canvasSrc;
            imgEl.classList.add('failed-image');
          }
        } catch (error) {
          captureException(error);
        }
      }
    }

    img.onload = insetText;
    focus();
  }, [focus]);
  const [isUploading, setIsUploading] = useState(false);
  const defaultInsertFiles = useCallback(async (files: Array<File | string | Gif>) => {
    if (!targetRef.current) return console.error('missing targetRef');
    setIsUploading(true);
    let range = getRangeOrEnd(targetRef.current);

    if (range.collapsed && range.endContainer.nodeName === 'LI') {
      if (!(range.endContainer.textContent || '').trim()) document.execCommand('insertParagraph');else {
        document.queryCommandState('insertorderedlist') && document.execCommand('insertorderedlist');
        document.queryCommandState('insertunorderedlist') && document.execCommand('insertunorderedlist');
      }
    }

    focus(); // @TODO probably need to use div instead of text node to get line break

    let loadingNodes = files.map(file => getImage(file));
    loadingNodes.forEach(el => el && document.execCommand('insertHtml', false, el.outerHTML));
    let imageIds = loadingNodes.map(el => el.id);
    let media: Array<string | undefined> = []; // try/catch createMediaLink to be able to remove loadingNodes if it fails

    try {
      media = await Promise.all(files.map(file => {
        if (file instanceof File && file.size > 20971520) {
          return '[File size too large]';
        } // @todo remove when we support videos.


        if (file instanceof File && String(file.type).startsWith('video/')) {
          return '[File not supported]';
        }

        return createMediaLink(file);
      }));
    } catch (err) {
      captureException(err);
      imageIds.forEach(imageId => {
        const img = (document.getElementById(imageId) as HTMLImageElement | null);
        img && img.remove();
      });
    }

    media.map((url, index) => {
      const img = (document.getElementById(imageIds[index]) as HTMLImageElement | null);
      if (!img) return;
      if (url === '[File size too large]') return insertFailedImage(img, 'File size too large');
      if (url === '[File not supported]') return insertFailedImage(img, 'File not supported');
      if (!url) return insertFailedImage(img, 'Failed to upload'); // Remove CORS policy to image, since our BE will not allow it

      img.removeAttribute('crossOrigin');
      img.setAttribute('src', url || ''); // Remove uploading class after the new src has been loaded

      img.onload = () => img.classList.remove('uploading');
    });
    focus();
    setIsUploading(false);
  }, [createMediaLink, focus, insertFailedImage]);
  const insertFiles = insertFilesCallback || defaultInsertFiles;
  let dragHandlers = useDragFile(dropFilesCallback === false ? () => {} : dropFilesCallback || insertFiles);
  const updateLinkedMentions = useUpdateLinkedMentions(); // Upload images from pasted text to media-service, if successfull, we replace the img src, if it fails we replace with a hint text
  // @TODO: better identify if all images got replaced and block Posting button

  const handlePastedImages = useCallback(async () => {
    const imgs = (document.querySelectorAll('img.pasted-image') as NodeListOf<HTMLImageElement>);
    imgs.forEach(img => {
      // Don't need to reupload images already in our media service
      if (img.src.startsWith(MEDIA_SERVICE_URL) || img.src.startsWith(MEDIA_CDN_URL)) return;

      img.onload = async () => {
        try {
          if (img.classList.contains('pasted-image') == false) return;
          const newUrl = await createMediaLink(img.src, true);
          img.setAttribute('src', newUrl || '');
          img.removeAttribute('crossOrigin');
          img.classList.remove('pasted-image');
        } catch (err) {
          // remove current image to avoid external image
          insertFailedImage(img, 'Failed to upload');
          captureException(err);
        }
      };

      img.onerror = () => {
        insertFailedImage(img, 'Failed to upload');
      };
    });
  }, [createMediaLink, insertFailedImage]); // special paste handler, will detect and embed media
  // and will paste only the plain text version of the clipboard
  // @NOTE when copying styled text, the clipboard will typically have two items representing the same text, one for html and one for plain

  const onPaste = useCallback((e: any) => {
    e.preventDefault(); // @NOTE: Only memos handle inline images today

    const isMemo = markdownProcessor === memoMarkdown;
    const isPost = markdownProcessor === postsMarkdown;
    let isMSWordPasted = false;

    try {
      const {
        items
      } = e.clipboardData;

      for (let item of items) {
        if (!isMSWordPasted && (isMemo || isPost) && (String(item.type).startsWith('image/') || String(item.type).startsWith('video/'))) {
          const file: File = item.getAsFile();
          file && dropFilesCallback ? dropFilesCallback([file]) : insertFiles([file]);
        } else if (item.type === 'text/plain') {
          // get text representation of clipboard
          const {
            text: draft,
            isMSWord
          } = getMarkdownFromPastedText(e);
          isMSWordPasted = isMSWord;
          const {
            text,
            mentions
          } = preHydrateText(draft); // If text is a plain text single lined (no markdown), we dont need to format it before pasting

          if (draft === text && !isMarkdownParseableRegex.test(text) && !/\n/.test(text)) {
            document.execCommand('insertHTML', false, text);
            return;
          }

          let wrapper: HTMLElement | null = document.createElement('SPAN');
          wrapper.innerHTML = renderToString(<ClientProvider client={client}>
                <Formatter text={text} mentions={mentions} processor={markdownProcessor} />
              </ClientProvider>);
          wrapper = wrapper.querySelector(`.${baseTextStyle}`);
          if (!wrapper) return;
          const imgs = wrapper.querySelectorAll('img'); // Add CORS policy to image, to be able to get Gif and outter sources
          // imgs.forEach(el => (el.crossOrigin = 'Anonymous'))

          imgs.forEach(el => isMemo ? el.classList.add('pasted-image') : el.remove());
          wrapEmojiCharacters(wrapper);
          const range = document.getSelection()?.getRangeAt(0);
          const endContainer = range?.endContainer;
          const parentElement = endContainer && (endContainer.nodeType === 3 ? endContainer.parentNode : endContainer) || undefined; // To prevent soft breaks inside list items, insert each new line/child in a different paragraph/list-item

          if (parentElement?.nodeName === 'LI') {
            const normalizedChilds = [...wrapper.children].flatMap(c => /^UL|OL$/.test(c.tagName) ? [...c.children] : c);

            for (let child of normalizedChilds) {
              document.execCommand('insertHTML', false, child.innerHTML);
              if (child !== wrapper.lastChild) document.execCommand('insertParagraph');
            }
          } else {
            document.execCommand('insertHTML', false, wrapper.innerHTML || '');
          }

          updateLinkedMentions();
          handlePastedImages();
        }
      }
    } catch (err) {
      console.error('## Error on pasting', err);
    }
  }, [dropFilesCallback, insertFiles, markdownProcessor, updateLinkedMentions, handlePastedImages]);
  const onCopy = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    getMarkdownFromCopiedText(e, plaintext);
    e.preventDefault();
  }, [plaintext]);
  const onCut = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    getMarkdownFromCopiedText(e, plaintext);
  }, [plaintext]);
  const clear = useCallback(() => targetRef.current && (targetRef.current.innerHTML = ''), []);
  const concat = useCallback((text: string) => targetRef.current && (targetRef.current.innerHTML = targetRef.current.innerHTML + text), []); // @TODO how to handle when this value is updated?

  let initialNode = useState<ReactNode>(() => {
    let init = persistKey ? localStorage.getItem(persistKey) || '' : initialText || '';
    let mentions: MentionAttachments = initialMentions;

    if (persistKey) {
      let draft = localStorage.getItem(persistKey) || '';
      const {
        text: draftText,
        mentions: draftMentions
      } = preHydrateText(draft);
      init = draftText;
      mentions = draftMentions;
    }

    return <Formatter text={init} mentions={mentions} processor={// @TODO: Bandaid solution. Initial text for posts is causing range issues. Try editing a post and mentioning a user.
    markdownProcessor === postsMarkdown ? memoMarkdown : markdownProcessor} removeLinks />;
  }); // dev check for something I worry may be problematic in the future

  let initialValueRef = useRef(initialText);

  if (initialText !== initialValueRef.current) {
    initialValueRef.current = initialText;
    console.error('## initialValue changed, this is not supported and the change will be ignored');
  } // helper must be started with command, critically it wraps the helper key in a span for use by InputHelper positioning


  const startHelper = useCallback((type: HelperType) => document.execCommand('insertHtml', false, type), []);
  useEffect(() => {
    if (!targetRef.current) return;
    let observer = new MutationObserver(() => {
      requestIdleCallback(() => listenersRef.current.forEach(cb => targetRef.current && cb(targetRef.current)), {
        timeout: 500
      });
    });
    observer.observe(targetRef.current, {
      childList: true,
      // observe direct children
      subtree: true,
      // and lower descendants too
      attributes: true,
      characterData: true
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    // Reinforce checking for isEmpty after first render
    checkMention();
    checkLink(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputKeyDown(_e: React.KeyboardEvent<HTMLDivElement>) {
    if (plaintext && targetRef.current && markdownProcessor === postsMarkdown) {
      removeStyleAndFormattingTags(targetRef.current);
    }
  }

  let input = <div {...dragHandlers} className={cx(className, baseTextStyle, baseStyle, plaintext ? simplifiedStyle : textAreaStyle, isEmpty && !isUploading && 'empty', isUploading && 'uploading')} ref={targetRef} contentEditable suppressContentEditableWarning onInput={checkMention} onMouseUp={checkMention} onKeyUp={checkLiveEdit} onKeyDown={handleInputKeyDown} role="textbox" data-placeholder={placeholder} onPaste={onPaste} onCopy={onCopy} onCut={onCut} onBlur={onBlur}>
      {initialNode}
    </div>;
  const getInputRange = useCallback(() => {
    return getRangeInTarget(targetRef.current) || null;
  }, []);
  useLayoutEffect(() => {
    targetRef.current?.addEventListener('click', e => {
      // prevent links from working inside of the input.
      // we may want to extend this to other elements too, not sure yet
      // @ts-ignore ??
      if (e.srcElement?.tagName === 'A' && e.srcElement?.closest('.' + baseStyle)) {
        e.preventDefault();
        e.stopImmediatePropagation(); // opens link modal for links in EditMemo that aren't asset/user mentions

        if (editMemo && // @ts-ignore
        !e.srcElement?.classList.contains('asset-mention') && // @ts-ignore
        !e.srcElement?.classList.contains('user-mention')) {
          const range = document.createRange(); // @ts-ignore

          range.selectNodeContents(e.srcElement); // @ts-ignore

          openLinkModal(range, modal, e.srcElement.href);
        }
      }
    });
  }, [modal, editMemo]);
  useLayoutEffect(() => {
    if (initialText && !hasInitialized) {
      checkLiveEdit();

      if (shouldFocusStart) {
        focus(true);
        setHasInitialized(true);
      }
    }
  }, [checkLiveEdit, focus, hasInitialized, initialText, mentionData, shouldFocusStart]);
  useKeyDown([...HelperOwnedKeys, 'ArrowLeft', 'ArrowRight'], e => {
    // noop if we are not focused
    if (!getInputRange()) return; // This prevents navigating the cursor while mentioning

    if (mentionData && HelperOwnedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    } // Prevent soft breaks


    if (e.key === 'Enter' && (e.shiftKey || e.altKey)) {
      e.preventDefault();
      return document.execCommand('insertParagraph');
    }

    if (['ArrowLeft', 'ArrowRight'].includes(e.key) && e.shiftKey === false) requestIdleCallback(() => checkMention(), {
      timeout: 500
    });
  });
  const canSave = useMemo(() => {
    return !isUploading;
  }, [isUploading]);
  const helper: Helper = useMemo(() => {
    return {
      search: mentionData?.search || false,
      leftText: mentionData?.leftText || false,
      type: mentionData?.type || false,
      onSelect: async (link: string, text: string, payload: HelperPayload, preventSpace: boolean) => {
        if (!targetRef.current || !mentionData?.type) return;
        addMentionToElement(targetRef.current, link, text, payload, mentionData, preventSpace, updateLinkedMentions);
        !preventSpace && setMentionedWithAutoSpace(text);
      }
    };
  }, [mentionData, updateLinkedMentions]);
  const editor: TextEditorValue = {
    getMarkdown,
    getPlaintext,
    clear,
    focus,
    input,
    inputRef: targetRef.current,
    insertFiles,
    listen,
    getInputRange,
    canSave,
    helper,
    startHelper,
    concat,
    getPlaintextLength
  };
  useEffect(() => {
    if (persistKey) {
      const debouncedPersist = debounce(() => {
        if (!targetRef.current) return;
        const markdown = getMarkdown();
        if (markdown) localStorage.setItem(persistKey, markdown);
        if (!markdown) localStorage.removeItem(persistKey);
      }, 500);
      return listen(debouncedPersist);
    }
  }, [persistKey, listen, getMarkdown]);
  useEffect(() => {
    if (initialText && plaintext && targetRef.current && markdownProcessor === postsMarkdown) {
      wrapPostLinks(targetRef.current);
    }
  }, [initialText, markdownProcessor, plaintext]);
  useImperativeHandle(ref, () => editor);
  return <TextEditorContext.Provider value={editor}>
      <MentionsContext.Provider value={initialMentions || {}}>{children}</MentionsContext.Provider>
    </TextEditorContext.Provider>;
}

let Provider = React.forwardRef(_Provider);

function Input(props: HTMLDivProps) {
  let {
    input
  } = useContext(TextEditorContext);
  if (input) return React.cloneElement(input, { ...input.props,
    className: cx(input.props.className, props.className),
    ...props
  });
  return null;
}

export const TextEditor = {
  Provider,
  Input
};
const baseStyle = "bsokgrl";
const simplifiedStyle = "sd9a807";
const textAreaStyle = "t194xvhz";

require("../../../.linaria-cache/packages/oxcart/src/scopes/text-editor/TextEditor.linaria.module.css");