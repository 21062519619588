import React, { useCallback, useState, useMemo } from 'react';
import { FollowStatus, HydratedUserProfile, useFollowProfile, useUnfollowProfile } from '@commonstock/common/src/api/profile';
import { css, cx } from '@linaria/core';
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys';
import { SecondaryButton } from '../../composing/CSButton';
import { useEphemeralModal, useModal } from '../modal/Modal';
import Unfollow from './Unfollow';
import { userFollowed } from '../analytics/events.v1';
import { track } from '../analytics/mixpanel';
import { MenuItem, paneHeaderButton, PaneHeaderLabel, ProfileHeaderButton } from '../../components/styles';
import useLoggedOutModal from '../public/useLoggedOutModal';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { JoinModalPublic } from '../public/JoinModalPublic';
import { useTheme } from 'src/theme/ThemeContext';
type FollowButtonProps = {
  followText: string;
  onClick: () => void;
  active?: boolean;
  cardItem?: boolean;
};
export const FollowButton = ({
  followText,
  onClick,
  active,
  cardItem
}: FollowButtonProps) => <SecondaryButton small onClick={onClick} active={!!active} className={cx(cardItem && carouselFollowProfileClass)}>
    {followText}
  </SecondaryButton>;
type PartialUserProfile = {
  user_uuid: string;
  private: boolean;
  follow_status: FollowStatus;
};

function FollowProfile({
  profile: currentProfile,
  profilePortfolio,
  isDropdown,
  onboarding,
  cardItem,
  setAnalyticsFollowUUIDs
}: {
  profile: PartialUserProfile | HydratedUserProfile;
  profilePortfolio?: boolean;
  isDropdown?: boolean;
  onboarding?: boolean;
  cardItem?: boolean;
  rounded?: boolean;
  setAnalyticsFollowUUIDs?: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  let profile: PartialUserProfile = useMemo(() => ({ ...currentProfile,
    user_uuid: 'uuid' in currentProfile ? currentProfile.uuid : currentProfile.user_uuid
  }), [currentProfile]);
  const refetchDependents = useRefetchByKeys('get-following', 'get-followers', 'user-profile', 'profile-username');
  const postFollow = useFollowProfile();
  const [optimisticFollowing, setOptimisticFollowing] = useState<undefined | FollowStatus>(undefined);
  const loggedOutModal = useLoggedOutModal(`Follow`);
  const onClickFollow = useCallback(async (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    track('FollowUser:Tap', {
      user_uuid: profile.user_uuid
    });

    if (e && 'preventDefault' in e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setOptimisticFollowing(profile.private ? FollowStatus.REQUESTED : FollowStatus.FOLLOWED);
    const data = {
      meta: {
        user_uuid: profile.user_uuid
      }
    };

    try {
      const res = await postFollow(data);
      res.success && userFollowed(res.success.payload);

      if (setAnalyticsFollowUUIDs) {
        setAnalyticsFollowUUIDs(currentArr => [...currentArr, profile.user_uuid]);
      }
    } catch (err) {
      // assume this means we failed, reset optimistic state
      alert('Unable to follow');
      setOptimisticFollowing(undefined);
    }

    refetchDependents();
  }, [profile.user_uuid, profile.private, refetchDependents, loggedOutModal, postFollow, setAnalyticsFollowUUIDs]);
  const postUnfollow = useUnfollowProfile();
  const {
    actions
  } = useModal();
  const unfollow = useCallback(async () => {
    track('UnfollowUser:Tap', {
      user_uuid: profile.user_uuid
    });
    setOptimisticFollowing(FollowStatus.NOT_FOLLOWED);
    actions.close();

    try {
      await postUnfollow({
        meta: {
          user_uuid: profile.user_uuid
        }
      });

      if (setAnalyticsFollowUUIDs) {
        setAnalyticsFollowUUIDs(currentArr => currentArr.filter(x => x !== profile.user_uuid));
      }
    } catch (err) {
      alert('Unable to unfollow'); // assume this means we failed, reset optimistic state

      setOptimisticFollowing(undefined);
    }

    refetchDependents();
  }, [postUnfollow, setAnalyticsFollowUUIDs, refetchDependents, profile.user_uuid, actions]);
  const modal = useEphemeralModal();
  const stopFollowingModal = useCallback((e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (e && 'preventDefault' in e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!onboarding) modal(<Unfollow userUuid={profile.user_uuid} onUnfollow={unfollow} />);else unfollow();
  }, [modal, onboarding, profile.user_uuid, unfollow]);
  let isPending = profile.follow_status === FollowStatus.REQUESTED;
  let isFollowedByMe = profile.follow_status === FollowStatus.FOLLOWED; // override with optimistic state, according to profile privacy state

  if (optimisticFollowing !== undefined) {
    isPending = optimisticFollowing === FollowStatus.REQUESTED;
    isFollowedByMe = optimisticFollowing === FollowStatus.FOLLOWED;
  }

  const onClick = isFollowedByMe || isPending ? stopFollowingModal : onClickFollow;
  const followText = isFollowedByMe ? 'Following' : isPending ? 'Pending' : 'Follow';
  const {
    isMobile
  } = useTheme();
  if (isDropdown) return <MenuItem onClick={onClick}>{followText}</MenuItem>;
  if (profilePortfolio) return <ProfileHeaderButton onClick={onClick} active={isFollowedByMe} className={cx(paneHeaderButton)} mobile={isMobile}>
        <PaneHeaderLabel>{followText}</PaneHeaderLabel>
      </ProfileHeaderButton>;
  return <FollowButton followText={followText} onClick={onClick} active={isFollowedByMe} cardItem={cardItem} />;
}

export default FollowProfile;
const carouselFollowProfileClass = "c16yocdz";

require("../../../.linaria-cache/packages/oxcart/src/scopes/profile/FollowProfile.linaria.module.css");