import config from '../config'
import { Env } from '../config/config.default'

const ImageSizingRegex = /.*?-(\d+)x(\d+)\.(png|jpg|jpeg|gif)/
export function getImageSizing(imageUrl: string) {
  const sizing: null | string[] = imageUrl.match(ImageSizingRegex)
  return sizing ? { width: Number(sizing[1] || 0), height: Number(sizing[2] || 0) } : null
}

const env = config.CS_ENV.toLowerCase()
export const MEDIA_SERVICE_URL = `https://s3.amazonaws.com/media-service.${
  env !== Env.Prod ? env : 'production'
}.commonstock.com/`
export const MEDIA_CDN_URL = `https://cdn${env !== Env.Prod ? '-' + env : ''}.commonstock.com/`

function isOutsideSourcedImage(url: string) {
  return !url.startsWith(MEDIA_SERVICE_URL) && !url.startsWith(MEDIA_CDN_URL)
}

// Hard code replace the media links, until BE find a way to replace on DB
export function replaceMediaUrls(url: string, width?: number, height?: number) {
  if (isOutsideSourcedImage(url)) return url

  let newUrl = new URL(url.replace(MEDIA_SERVICE_URL, MEDIA_CDN_URL))

  // It doesnt handle gifs, so we use original url
  const isGif = url.match(/\.gif/)

  if (!isGif && width) {
    newUrl.searchParams.set('w', String(width))
    if (height) newUrl.searchParams.set('h', String(height))
    // Use min to make sure it will crop the image if the original one is not square
    // Use max to maitain displayed aspect ratio
    newUrl.searchParams.set('fit', height ? 'min' : 'max')
  }

  if (isGif) {
    // This turns gif into webm to reduce size and helps performance
    newUrl.searchParams.set('auto', 'format')
  }

  return newUrl.toString()
}
