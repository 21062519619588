import { css } from '@linaria/core';
import { Colors, FontWeights, ZIndexLayers } from './constants';
import { hiddenScrollbar, scrollbar } from './scrollbar';
export const scrollYHidden = "s9qhs4k";
export const scrollY = "s18d5gjp";
export const noBoxShadow = "n4usweg";
export const flexCentered = "f21ovgr";
export const flexJustifiedCenter = "f19yohy7";
export const flexJustified = "f2lwg7h";
export const linkClass = "l9vqxa7";
export const gradientText = "gz3b66v";
export const relative = "r1ldw5ih";
export const devTransparent = "dywp185";
export const devTransparentOutline = "d1p1cmxh";
const CardWidthSmall = '288px';
const CardWidthMax = '560px';
export const responsiveImageContainer = "r1bkaaef";
export const responsiveImage = "rqqo9ny";

require("../../.linaria-cache/packages/oxcart/src/theme/AtomicClasses.linaria.module.css");