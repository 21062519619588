import { css, cx } from '@linaria/core';
import React, { ReactNode } from 'react';
import { useTheme } from '../theme/ThemeContext';
import { Theme } from '../theme/constants';
type SkeletonProps = {
  children?: ReactNode;
  noShimmer?: boolean;
  className?: string;
  w?: string | number;
  h: string | number;
  zIndex?: number;
  borderRadius?: string | number;
};

const Skeleton = ({
  children,
  noShimmer,
  className,
  w,
  h,
  zIndex,
  borderRadius,
  ...rest
}: SkeletonProps) => {
  const {
    theme
  } = useTheme();
  const style: React.CSSProperties = {
    [('--color-skeleton' as any)]: theme === Theme.Light ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.10)',
    [('--color-shimmer' as any)]: theme === Theme.Light ? 'rgba(255, 255, 255, 0.35)' : 'rgba(0, 0, 0, 0.15)',
    width: w === undefined ? '100%' : typeof w === 'string' ? w : `${w}rem`,
    height: typeof h === 'string' ? h : `${h}rem`,
    overflow: 'hidden',
    position: 'relative',
    background: 'var(--color-skeleton)',
    zIndex,
    borderRadius: borderRadius !== undefined && typeof borderRadius === 'string' ? borderRadius : `${borderRadius}rem`
  };
  return <div className={cx(className)} style={style} {...rest}>
      {children ? children : null}
      {!noShimmer && <div className={shimmerClass} />}
    </div>;
};

const shimmerClass = "sdgkcf7";
export const SkeletonCircle = ({
  h,
  zIndex,
  noShimmer
}: {
  h: string | number;
  zIndex?: number;
  noShimmer?: boolean;
}) => <Skeleton w={h} h={h} zIndex={zIndex} noShimmer={noShimmer} borderRadius="100%" />;
export const SkeletonBlock = ({
  h,
  w,
  borderRadius
}: {
  h: string | number;
  w: string | number;
  borderRadius?: number | number;
}) => <Skeleton className={radiusClass} h={h} w={w} borderRadius={borderRadius} />;
const radiusClass = "r17erxzh";

require("../../.linaria-cache/packages/oxcart/src/components/Skeleton.linaria.module.css");