import { FeedType, GlobalFeedSortType } from '@commonstock/common/src/api/feed'
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useRouter } from 'next/router'
import { GlobalFeedSortKey } from 'src/scopes/feed/constants'

export function useFeedType() {
  const router = useRouter()
  const [sortBy] = usePersistedState<GlobalFeedSortType>(GlobalFeedSortKey, GlobalFeedSortType.TOP)

  let feedType

  switch (router.pathname) {
    case '/home':
      feedType = sortBy
      break
    case '/following':
      feedType = FeedType.FOLLOWING
      break
    default:
      break
  }

  return {
    feedType
  }
}
