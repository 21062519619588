import { useParams } from '../../utils/useParams'
import {
  useGetBlockedUsers,
  useGetProfile,
  useFollowers,
  useFollowing,
  FollowStatus,
  PatchProfileParams,
  usePatchProfileAction,
  useGetUsernameUuid,
  UserProfile
} from '@commonstock/common/src/api/profile'
import { useCallback } from 'react'
import { useAuth } from '../auth/AuthContext'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'

type ProfileParams = { userUuid?: string; username?: string }
export function useUserProfile() {
  const { userUuid } = useAuth()
  const params = useParams() as ProfileParams

  const [usernameProfile, usernameFail, , refetchUsernameProfile] = useGetUsernameUuid(
    { meta: { username: params.username || '' } },
    { paused: !params.username }
  )

  const [uuidProfile, uuidFail, , refetchUuidProfile] = useGetProfile(
    { meta: { user_uuid: params.userUuid || '' } },
    { paused: !params.userUuid }
  )

  let profile = usernameProfile || uuidProfile
  let refetchProfile = params.username ? refetchUsernameProfile : refetchUuidProfile
  let profileFail = params.username ? usernameFail : uuidFail

  const isMe = params.userUuid === userUuid || profile?.user_uuid === userUuid

  return { profile, profileFail, isMe, params, refetchProfile, userUuid: params.userUuid || profile?.user_uuid }
}

export function isProfileVisible(profile?: UserProfile, isMe?: boolean) {
  if (!profile) return false
  return isMe || !profile?.private || profile?.follow_status === FollowStatus.FOLLOWED
}

export function useBlockedUsers() {
  const { userUuid } = useAuth()
  const [blockedUsers, , , refetchBlockedUsers] = useGetBlockedUsers({ meta: { user_uuid: userUuid } })

  return { blockedUsers, refetchBlockedUsers }
}

export function useMyFollowers() {
  const { userUuid } = useAuth()
  const [followers = []] = useFollowers({ meta: { user_uuid: userUuid } })
  return followers
}

export function useMyFollowing() {
  const { userUuid } = useAuth()
  const [following = []] = useFollowing({ meta: { user_uuid: userUuid } })
  return following
}

export function useUpdateProfile() {
  let patchProfile = usePatchProfileAction()

  const refetchProfile = useRefetchByKeys('my-profile', 'user-profile', 'profile-username')

  const updateProfile = useCallback(
    async (data: PatchProfileParams) => {
      let resource = await patchProfile(data)
      refetchProfile()
      return resource
    },
    [patchProfile, refetchProfile]
  )

  return updateProfile
}
