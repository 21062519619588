import React, { useCallback } from 'react';
import { Tag as TagType } from '@commonstock/common/src/api/tag';
import { cx, css } from '@linaria/core';
import CSLink from '../../composing/CSLink';
import { SecondaryButton } from '../../composing/CSButton';
import { isLoggedIn } from '@commonstock/common/src/auth';
import useLoggedOutModal from '../public/useLoggedOutModal';
import { JoinModalPublic } from '../public/JoinModalPublic';
type Props = {
  tag: TagType;
  onClick?: (tag: TagType) => void;
  inactive?: boolean;
  to?: string;
  preventNavigation?: boolean;
};

function Tag({
  tag,
  onClick,
  inactive,
  to = '',
  preventNavigation
}: Props) {
  const loggedOutModal = useLoggedOutModal(`Tag`);
  const onClickTag = useCallback(() => {
    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    !preventNavigation && onClick && onClick(tag);
  }, [loggedOutModal, onClick, preventNavigation, tag]);
  return onClick || preventNavigation || !isLoggedIn() ? <SecondaryButton small onClick={preventNavigation ? undefined : onClickTag} className={cx(inactive != undefined && !inactive && 'active', tagStyle)}>
      {tag.display_name}
    </SecondaryButton> : <CSLink capture unstyled href={to} className={tagStyle}>
      <SecondaryButton small className={cx(inactive != undefined && !inactive && 'active')}>
        {tag.display_name}
      </SecondaryButton>
    </CSLink>;
}

export default Tag;
const tagStyle = "t11hs9g5";

require("../../../.linaria-cache/packages/oxcart/src/scopes/tags/Tag.linaria.module.css");