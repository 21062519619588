import { createUseFetchAction, createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, CacheType } from '@commonstock/client/src/constants'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { PostFeedItem, PostFeedOutput } from './feed'
import { HydratedUserProfile } from './profile'
import { CommentSummary } from './comment'
import { Likes } from './like'

export const BASE_POST_PATH = '/feed/v3/post/'
type PostParams = { meta: { uuid: string } }
export const useGetPost = createUseFetch<PostFeedItem, PostParams>({
  key: 'post',
  path: ({ meta: { uuid } }) => `${BASE_POST_PATH}${uuid}`
})

export type PostPostParams = {
  json: {
    summary: string
    media: string[]
    show_url_card: boolean
    related_trades: string[]
  }
}

export type PostResponse = {
  archived_at: string | null
  asset_mentions: any[]
  author_tags: any[]
  author_uuid: string
  card_url: string | null
  comments: CommentSummary
  content_format: string | null
  created_at: string
  enrichment_tags: any[]
  ext_ref: string | null
  likes: Likes
  media: any[]
  published_at: string | null
  ref_asset_mentions: any[]
  summary: string | null
  title: null
  updated_at: string | null
  user: HydratedUserProfile
  user_mentions: any[]
  uuid: string
  visibility: boolean | string | null
}

export const usePostPostAction = createUseFetchAction<PostResponse, PostPostParams>({
  key: 'post-create',
  method: HttpMethods.Post,
  path: '/user-content/v2/posts/create'
})

export type UpdatePostParams = {
  json: {
    uuid: string
    summary: string
    media: string[]
    related_trades: string[]
    show_url_card: boolean
  }
}

export const useUpdatePostAction = createUseFetchAction<PostResponse, UpdatePostParams>({
  key: 'post-update',
  method: HttpMethods.Put,
  path: ({ json: { uuid } }) => `/user-content/v2/posts/${uuid}`
})

type MemoDeleteParams = {
  meta: {
    uuid: string
  }
}

export const useDeletePostAction = createUseFetchAction<string, MemoDeleteParams>({
  key: 'post-delete',
  method: HttpMethods.Delete,
  path: params => `/user-content/v2/posts/${params.meta.uuid}`
})

export const useUnarchiveMemoAction = createUseFetchAction<void, MemoDeleteParams>({
  key: 'post-unarchive',
  method: HttpMethods.Post,
  path: params => `/user-content/posts/${params.meta.uuid}/unarchive`
})

const pagerOptions: PagerOptions<PostFeedOutput, any> = {
  getCursor: (page, resource) => {
    let list = resource?.success?.payload?.items
    let last = list && list[list.length - 1]
    return { query: { limit: 10, after_uuid: last?.uuid } }
  },
  // @NOTE: checking payload since BE is returning null for empty lists
  isTerminal: resource =>
    !!(resource.success && (!resource.success.payload || resource.success.payload.items.length < 10))
}

type UserPostParams = {
  query: {
    target_uuid: string
    before_uuid?: string
    after_uuid?: string
    limit?: number
  }
}
export const useGetUsersPosts = createUsePagedFetch<PostFeedOutput, UserPostParams>(
  {
    key: 'users-posts',
    path: '/feed/v3/user-posts',
    cache: CacheType.Disk
  },
  pagerOptions
)

type HydrationParams = {
  json: {
    urls: string[]
  }
}

export type HydratedOutput = {
  [key: string]: {
    description: string
    destination: string | null
    display_url: string
    icon: string
    image: string | null
    site_name: string
    title: string
    url: string
    successfully_hydrated: boolean
  }
}
export type HydratedLinkItem = {
  description: string
  destination: string | null
  display_url: string
  icon: string
  image: string | null
  site_name: string
  title: string
  url: string
}

export const useGetPostHydratedLink = createUseFetchAction<HydratedOutput, HydrationParams>({
  key: 'hydrate-post-link',
  path: '/link/hydrate',
  method: HttpMethods.Post,
  cacheTime: 0
})
