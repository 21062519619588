import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { UrlObject } from 'url'

export function useGoto(url: UrlObject | string) {
  let router = useRouter()
  return useCallback(() => {
    // @ts-ignore not sure how to fix
    router.push(url)
  }, [router, url])
}
