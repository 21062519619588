import { ClientProvider } from '@commonstock/client/src/react/context'
import AnalyticsController from 'src/scopes/analytics/AnalyticsController'
import { AuthProvider } from 'src/scopes/auth/AuthContext'
import { ThemeProvider } from 'src/theme/ThemeContext'
import client from 'src/client'
import { DevProvider } from 'src/scopes/dev/DevContext'

import OxcartProviders from 'src/Providers'

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <ClientProvider client={client}>
      <AuthProvider>
        <ThemeProvider>
          <AnalyticsController />
          <OxcartProviders>
            <DevProvider>{children}</DevProvider>
          </OxcartProviders>
        </ThemeProvider>
      </AuthProvider>
    </ClientProvider>
  )
}

export default Providers
