export const FeedStateKey = 'cs:feed-state3'
export const FeedContentOwnerKey = 'cs:feed-content-owner'
export const FeedPostsEducationModalKey = 'cs:posts-education-modal'

export const FeedAlphaEndpointKey = 'cs:feed-alpha-endpoint-key'

export const GlobalFeedPeriodKey = 'cs:global-feed-period-key'
export const GlobalFeedSortKey = 'cs:global-feed-sort-key'

export enum InfoCardState {
  Dismissed = 'Dismissed',
  NotDismissed = 'NotDismissed'
}

export enum CardLayoutTypes {
  Default = 'Default',
  Upvote = 'Upvote'
}

export function isUpvoteLayout(cardLayout?: CardLayoutTypes): cardLayout is CardLayoutTypes {
  return cardLayout ? cardLayout === CardLayoutTypes.Upvote : false
}
