import React, { useCallback } from 'react'
import { useReportAction, ReportContentType } from '@commonstock/common/src/api/report'
import useFetchSubmit from '../hooks/useFetchSubmit'
import { MenuItem } from './styles'
import { reportFailedResource } from '../dev/sentry'
import AnimatedEllipsis from './AnimatedEllipsis'

function ReportContentMenuItem({
  uuid,
  title,
  contentType,
  rootContentType,
  rootUuid
}: {
  uuid: string
  title: string
  contentType: ReportContentType
  rootContentType?: string
  rootUuid?: string
}) {
  let { submit, pending, fail, success } = useFetchSubmit(useReportAction(), reportFailedResource)
  let onClick = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      submit({
        json: {
          content_type: contentType,
          content_uuid: uuid,
          content_title: title,
          root_type: rootContentType,
          root_uuid: rootUuid
        }
      })
    },
    [submit, uuid, title, contentType, rootContentType, rootUuid]
  )
  return (
    <MenuItem isDestructive disabled={!!(pending || success)} onClick={pending || success ? undefined : onClick}>
      {fail ? 'Report Failed' : success ? 'Reported' : 'Report'}
      {pending && <AnimatedEllipsis />}
    </MenuItem>
  )
}

export default ReportContentMenuItem
