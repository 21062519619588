import React, { useEffect } from 'react'
import { useAuth } from '../auth/AuthContext'
import { Routes } from './constants'
import PaneLayout from './PaneLayout'
import FixedHeader from './FixedHeader'
import { useRouter } from 'next/router'
import { setPersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { AuthenticateRedirectKey } from 'src/client/authenticator'
import dynamic from 'next/dynamic'
import { FullPageSpinner } from 'src/components/Spinner'
import { Authenticated } from '../auth/constants'
import { StandardScreenProps } from '../layout/constants'
import { ClientGate } from 'src/components/ClientGate'
import { isLoggedIn } from '@commonstock/common/src/auth'

const VerifyController = dynamic(() => import('../auth/VerifyController'))

export const ScrollerClass = 'scroller'

function Redirect({ to, from }: { to: string; from?: string }) {
  const router = useRouter()
  const isLoggingOut = from === Routes.Account
  useEffect(() => {
    router.push(to)
    from && !isLoggingOut && setPersistedState(AuthenticateRedirectKey, from)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

function StandardScreen({ children, onboarding, hasSingleColumn, publicScreen }: StandardScreenProps) {
  const router = useRouter()
  let { authenticated, myProfile } = useAuth()
  let isVerifyScreen = router.pathname === Routes.Verify
  const isLoggedInOnHomeScreen = isLoggedIn() && router.pathname === Routes.Home

  // @NOTE second check to ensure entire gloabl feed renders at same time to prevent jumping
  if (!publicScreen || isLoggedInOnHomeScreen) {
    if (authenticated === Authenticated.No) return <Redirect to={Routes.Login} from={router.asPath} />
    if (authenticated === Authenticated.Pending || !myProfile) return <FullPageSpinner />
  }

  if (myProfile && !myProfile.username && router.pathname !== Routes.OnboardProfile) {
    return <Redirect to={Routes.OnboardProfile} />
  }

  let singleColumn: boolean | undefined
  if (typeof hasSingleColumn === 'function') {
    singleColumn = hasSingleColumn()
  } else {
    singleColumn = hasSingleColumn
  }

  return (
    <>
      {!isVerifyScreen && <VerifyController />}
      <PaneLayout onboarding={onboarding} singleColumnLayout={!!singleColumn}>
        <ClientGate>{!onboarding && <FixedHeader hasSecondColumn={!singleColumn} />}</ClientGate>
        {children}
      </PaneLayout>
    </>
  )
}

export default StandardScreen
