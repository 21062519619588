import React, { MouseEvent } from 'react'
import { CardFooterActions } from '../../components/styles'
import { CommentIcon } from '../comments/CommentIcon'
import LikeIcon from '../likes/LikeIcon'
import { LikeTypes } from '@commonstock/common/src/api/like'
import ShareIcon from '../share/ShareIcon'
import { TradeFeedItem } from '@commonstock/common/src/api/feed'
import { destructTradeFeedItem } from '../trade/utils'
import { TradeDetailLink } from '../trade/TradeCard'
import { useCallback } from 'react'
import { useEphemeralModal, useModal } from '../modal/Modal'
import EditPost from '../posts/EditPost'
import { useRefetchPostDependents } from '../posts/postHooks'
import { SpacerV } from 'src/composing/Spacing'
import PseudoInputButton from 'src/components/PseudoInputButton'
import { useUser } from '../auth/AuthContext'
import { useFlags } from 'src/utils/useFlags'

function TradeFooterAction({ tradeFeedItem, detail }: { tradeFeedItem: TradeFeedItem; detail?: boolean }) {
  const { webTradeReasons } = useFlags()
  const { trade, user } = destructTradeFeedItem(tradeFeedItem)
  let [currentUser] = useUser()
  const refetchPostDependents = useRefetchPostDependents()

  const modal = useEphemeralModal()
  const { actions } = useModal()
  const onClickWhy = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      modal(
        <EditPost
          onFinish={() => {
            refetchPostDependents()
            actions.close()
          }}
          prelinkedTrade={tradeFeedItem}
        />
      )
    },
    [actions, modal, refetchPostDependents, tradeFeedItem]
  )
  if (!trade || !user) return null
  return (
    <>
      {user.uuid === currentUser?.user_uuid && webTradeReasons && (
        <>
          <SpacerV rem={0.5} />
          <PseudoInputButton onClick={onClickWhy} prompt="Why did you make this trade?" />
          <SpacerV rem={1} />
        </>
      )}
      <CardFooterActions>
        {detail ? (
          <CommentIcon count={trade.comments?.count} />
        ) : (
          <TradeDetailLink tradeFeedItem={tradeFeedItem}>
            <CommentIcon count={trade.comments?.count} />
          </TradeDetailLink>
        )}
        <LikeIcon
          likes={trade.likes}
          parentUuid={tradeFeedItem.uuid}
          parentType={LikeTypes.TRADE}
          authorUuid={tradeFeedItem.user_uuid}
        />
        <div />
        <ShareIcon trade uuid={tradeFeedItem.uuid} disabled={user.private} />
      </CardFooterActions>
    </>
  )
}

export default TradeFooterAction
