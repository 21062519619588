import { track } from '../mixpanel'
import { urlRegex } from '../../posts/utils'
import { PostResponse } from '@commonstock/common/src/api/post'

type PostPublishedObject = PostResponse & {
  related_trade_count: number
  repost?: boolean
}

export function postPublished(data: PostPublishedObject) {
  track('Post Published', {
    card_url: Boolean(data.card_url),
    image: Boolean(data.media.length),
    text_length: data.summary?.replace(urlRegex, '').length || 0,
    mentioned_users: data.user_mentions.map(um => um.uuid),
    mentioned_user_count: data.user_mentions.length,
    mentioned_assets: data.asset_mentions.map(am => `${am.symbol}:${am.type}`),
    mentioned_asset_count: data.asset_mentions.length,
    post_id: data.uuid,
    related_trade_count: data.related_trade_count,
    repost: Boolean(data.repost)
  })
}
