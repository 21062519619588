import React, { useMemo } from 'react';
import { css } from '@linaria/core';
import { FlexRow } from '../composing/Flex';
import { TextCaption } from '../composing/CSText';
import { Colors } from '../theme/constants';
import { DotCircle } from '../theme/NewIcons';
import { formatLargeMoney, formatTimeRelative } from '@commonstock/common/src/utils/format';
import { MemoFeedItem, PostFeedItem, TradeFeedItem, isMemoFeedItem, isPostFeedItem } from '@commonstock/common/src/api/feed';
import { destructMemoFeedItem } from '../scopes/memo/utils';
import { destructPostFeedItem } from '../scopes/posts/utils';
import { destructTradeFeedItem } from '../scopes/trade/utils';
import { SpacerH, Spacing } from '../composing/Spacing';
import { ProfileAvatar } from './Avatar';
type Props = {
  feedItem: MemoFeedItem | PostFeedItem | TradeFeedItem;
};

function SmallCardFooter({
  feedItem
}: Props) {
  const {
    memo,
    post,
    trade,
    user
  } = useMemo(() => {
    if (isMemoFeedItem(feedItem)) {
      const {
        memo,
        user
      } = destructMemoFeedItem(feedItem);
      return {
        memo,
        user
      };
    } else if (isPostFeedItem(feedItem)) {
      const {
        post,
        user
      } = destructPostFeedItem(feedItem);
      return {
        post,
        user
      };
    } else {
      const {
        trade,
        user
      } = destructTradeFeedItem(feedItem);
      return {
        trade,
        user
      };
    }
  }, [feedItem]);
  if (!memo && !post && !trade || !user) return null;
  const comments = memo?.comments || post?.comments || trade?.comments;
  const likes = memo?.likes || post?.likes || trade?.likes;
  const published_at = memo?.published_at || post?.published_at || trade?.created_at;
  return <FlexRow alignCenter>
      <ProfileAvatar border avatar={user.picture} name={user.name} size={0.75} />
      <SpacerH rem={0.375} />
      <TextCaption color={Colors.TextSecondary} ellipsis className={textClass}>
        {user.name}
        <Spacing margin={[0, 0.25, 0.125]}>
          <DotCircle size={0.25} color={Colors.TextSecondary} />
        </Spacing>
        {published_at && formatTimeRelative(published_at)}
        {!!comments?.count && <>
            <Spacing margin={[0, 0.25, 0.125]}>
              <DotCircle size={0.25} color={Colors.TextSecondary} />
            </Spacing>
            {comments.count} {comments.count === 1 ? 'Comment' : 'Comments'}
          </>}
        {likes?.value ? <>
            <Spacing margin={[0, 0.25, 0.125]}>
              <DotCircle size={0.25} color={Colors.TextSecondary} />
            </Spacing>
            {formatLargeMoney(likes.value)} likes
          </> : likes?.count ? <>
            <Spacing margin={[0, 0.25, 0.125]}>
              <DotCircle size={0.25} color={Colors.TextSecondary} />
            </Spacing>
            {likes.count} {likes.count === 1 ? 'like' : 'likes'}
          </> : null}
      </TextCaption>
    </FlexRow>;
}

export default SmallCardFooter;
const textClass = "t1kzir2r";

require("../../.linaria-cache/packages/oxcart/src/components/SmallCardFooter.linaria.module.css");