import { useEffect } from 'react'
import { track } from '../mixpanel'
import { LinkPreview, ShareType } from '@commonstock/common/src/api/link'
import { useFeedType } from 'src/scopes/feed/utils'

type ShareLinkGeneratedObject = {
  type: ShareType
  uuid: string
}

export function useTrackShareLinkGenerated(data: ShareLinkGeneratedObject, linkPreview?: LinkPreview) {
  const { feedType } = useFeedType()
  useEffect(() => {
    if (linkPreview) {
      const type = Object.keys(ShareType).find(k => ShareType[k as keyof typeof ShareType] === data.type)
      type &&
        track('Share Link Generated', {
          owner_uuid: linkPreview.content_owner_uuid,
          item_type: type,
          item_uuid: data.uuid,
          feed_type: feedType
        })
    }
  }, [data, feedType, linkPreview])
}
