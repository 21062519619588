import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { useTwitterAuthAction } from '@commonstock/common/src/api/profile'

export default function useTwitterAuthCallback() {
  const authorizeTwitter = useTwitterAuthAction()
  const refetchProfile = useRefetchByKeys('my-profile', 'user-profile', 'profile-username')

  const twitterAuthCallback = async (callback_url: string, source_url: string = location.href) => {
    const res = await authorizeTwitter({ query: { callback_url, source_url } })
    if (res && res.success) {
      window.location.href = res.success.payload.url
      refetchProfile()
    }
  }

  return twitterAuthCallback
}
