import React, { useCallback } from 'react';
import { css } from '@linaria/core';
import { sendbird } from '../chat/ChatContext';
import { HydratedUserProfile } from '@commonstock/common/src/api/profile';
import { Colors } from '../../theme/constants';
import { CheckCircle } from '../../theme/NewIcons';
import { TextSubhead } from '../../composing/CSText';
import { SecondaryButton, UnstyledButton } from '../../composing/CSButton';
import UserBlock from '../../components/UserBlock';
import { Expander, FlexRow } from '../../composing/Flex';
import { Spacing } from '../../composing/Spacing';
enum MemberStatusTypes {
  Joined = 'joined',
  Invited = 'invited',
}
type Props = {
  isSelected?: boolean;
  profile: HydratedUserProfile;
  onSelect?: (profile: HydratedUserProfile) => void;
  onRemove?: (profile: HydratedUserProfile) => void;
  onUninvite?: (profile: HydratedUserProfile) => void;
  isOperator: boolean;
  memberStatus: string | undefined;
  isAdmin?: boolean;
  disabled?: boolean;
};

const SelectUserItem = ({
  isSelected = false,
  profile,
  onSelect,
  onRemove,
  onUninvite,
  isOperator,
  memberStatus,
  isAdmin,
  disabled
}: Props) => {
  const isJoined = memberStatus === MemberStatusTypes.Joined;
  const isInvited = memberStatus === MemberStatusTypes.Invited;
  const onClickSelect = useCallback(() => {
    onSelect && onSelect(profile);
  }, [onSelect, profile]);
  const onClickRemove = useCallback(() => {
    onRemove && onRemove(profile);
  }, [onRemove, profile]);
  const currentUserId = sendbird.currentUser?.userId;
  return <Spacing padding={[0.75, 0]}>
      <UnstyledButton block onClick={onSelect && !isJoined && !isInvited ? onClickSelect : undefined} data-uuid={profile.uuid} className={containerClass}>
        <FlexRow>
          <UserBlock disabled={disabled} user={{ ...profile,
          uuid: profile.uuid
        }} />
          <Expander />
          {profile.uuid === currentUserId ? <TextSubhead color={Colors.TextSecondary}>You</TextSubhead> : isOperator ? // operator: show states invited / member, show buttons uninvite / remove
        <>
              {onSelect && !isJoined && !isInvited && <CheckCircle size={1.25} active={isSelected} color={Colors.TextSecondary} />}
              {onSelect && isJoined && <TextSubhead color={Colors.TextSecondary}>Already a member</TextSubhead>}
              {onSelect && isInvited && <TextSubhead color={Colors.TextSecondary}>Already invited</TextSubhead>}
              {onRemove && isJoined && <SecondaryButton small onClick={onClickRemove}>
                  Remove
                </SecondaryButton>}
              {onUninvite && isInvited && <TextSubhead color={Colors.TextSecondary}>Invited</TextSubhead>}
            </> : // not operator: show invited state or admin
        <>
              {isInvited && <TextSubhead color={Colors.TextSecondary}>Invited</TextSubhead>}
              {isAdmin && <TextSubhead color={Colors.TextSecondary}>Admin</TextSubhead>}
            </>}
        </FlexRow>
      </UnstyledButton>
    </Spacing>;
};

export default SelectUserItem;
const containerClass = "c1powgjl";

require("../../../.linaria-cache/packages/oxcart/src/scopes/chat-edit/SelectUserItem.linaria.module.css");