import { FeedReason } from '@commonstock/common/src/api/feed'
import { Twitter } from '../theme/Logos'
import { DollarSign, Like, Lightning, Message, Top, Followed, Verified, User } from '../theme/NewIcons'

export const reasonIcons = {
  [FeedReason.AssetMention]: DollarSign,
  [FeedReason.Interacted]: Message,
  [FeedReason.Performance]: Top,
  [FeedReason.Featured]: Lightning,
  [FeedReason.Followed]: Followed,
  [FeedReason.FollowedComment]: Message,
  [FeedReason.FollowedLike]: Like,
  [FeedReason.FollowedTwitter]: Twitter,
  [FeedReason.Verified]: Verified,
  [FeedReason.You]: User
}
