import { TradeFeedItem, TradeGroupFeedItem, MemoFeedItem, FeedResourceType } from '@commonstock/common/src/api/feed'

export function convertResourcesToFeedItems(feedItem: MemoFeedItem | TradeFeedItem | TradeGroupFeedItem) {
  const { resources } = feedItem
  const memoFeedItems: MemoFeedItem[] = Object.values(resources.memos).map(memo => ({
    ...feedItem,
    created_at: memo.created_at,
    user_uuid: memo.user_uuid,
    uuid: memo.uuid,
    resource_type: FeedResourceType.MEMO_PLUS
  }))
  const tradeFeedItems: TradeFeedItem[] = Object.values(resources.trades).map(trade => ({
    ...feedItem,
    created_at: trade.created_at,
    user_uuid: trade.user_uuid,
    uuid: trade.uuid,
    resource_type: FeedResourceType.TRADE
  }))
  const tradeGroupFeedItems: TradeGroupFeedItem[] = Object.values(resources.trade_groups).map(tradeGroup => ({
    ...feedItem,
    created_at: tradeGroup.created_at,
    user_uuid: tradeGroup.user_uuid,
    uuid: tradeGroup.uuid,
    resource_type: FeedResourceType.TRADE_GROUP
  }))
  return { memoFeedItems, tradeFeedItems, tradeGroupFeedItems }
}
