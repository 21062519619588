import React from 'react';
import LikeIcon from '../likes/LikeIcon';
import { LikeTypes } from '@commonstock/common/src/api/like';
import { Spacing } from '../../composing/Spacing';
import { PostFeedItem } from '@commonstock/common/src/api/feed';
import { destructPostFeedItem } from './utils';
import { CardLayoutTypes } from 'src/scopes/feed/constants';
import { css } from '@linaria/core';
import { Colors } from 'src/theme/constants';

function PostColumnActions({
  postFeedItem,
  cardLayout
}: {
  postFeedItem: PostFeedItem;
  detail?: boolean;
  cardLayout?: CardLayoutTypes;
}) {
  let {
    post,
    user
  } = destructPostFeedItem(postFeedItem);
  if (!post || !user) return null;
  return <div className={backgroundColumn}>
      <Spacing margin={[0, 1, 0, 0.5]}>
        <div>
          <LikeIcon likes={post.likes} parentUuid={post.uuid} parentType={LikeTypes.POST} authorUuid={user.uuid} cardLayout={cardLayout} />
        </div>
      </Spacing>
    </div>;
}

export default PostColumnActions;
const backgroundColumn = "b1nj3u2m";

require("../../../.linaria-cache/packages/oxcart/src/scopes/posts/PostColumnActions.linaria.module.css");