import React from 'react'
import { useParams } from '../../utils/useParams'
import { DetailCard } from '../../components/styles'
import { useGettradeGroup, useGetTrade } from '@commonstock/common/src/api/trade'
import { BrandSpinner } from '../../components/Spinner'
import { Spacing, SpacerV } from '../../composing/Spacing'
import { ParentTypes, useGetComment } from '@commonstock/common/src/api/comment'
import TradeFooterAction from './TradeFooterAction'
import TradeHeaderTop from './TradeHeaderTop'
import { cx } from '@linaria/core'
import RootComments from '../comments/RootComments'
import { useTrackTradeDetailImpression } from '../analytics/events.v1'
import CommentItem from '../comments/CommentItem'
import { useAuth } from '../auth/AuthContext'
import {
  isTradeFeedItem,
  isTradeGroupFeedItem,
  MemoResource,
  PostResource,
  TradeFeedItem,
  TradeGroupFeedItem
} from '@commonstock/common/src/api/feed'
import TradeHeader from './TradeHeader'
import TradeGroupFooterActions from './TradeGroupFooterActions'
import LinkedMemos from 'src/scopes/memo/LinkedMemos'
import { destructTradeFeedItem } from './utils'
import { useGetLikes } from '../likes/likeHooks'
import NotFound from '../../components/NotFound'
import LikeList from '../../components/LikeList'
import { LikeTypes } from '../../../../common/src/api/like'
import { isLoggedIn } from '@commonstock/common/src/auth'
import LinkedPosts from '../posts/LinkedPosts'
import { scrollY } from 'src/theme/AtomicClasses'

type Params =
  | { tradeId: string; tradeGroupId: undefined; commentId?: string }
  | { tradeId: undefined; tradeGroupId: string; commentId?: string }

function TradeDetail({
  isModal,
  tradeFeedItem,
  tradeUuid: _tradeUuid,
  tradeGroupUuid: _tradeGroupUuid
}: {
  isModal?: boolean
  tradeFeedItem?: TradeFeedItem | TradeGroupFeedItem
  tradeUuid?: string
  tradeGroupUuid?: string
}) {
  const { commentId, tradeId, tradeGroupId } = useParams<Params>()
  const { isModerator } = useAuth()

  const tradeUuid = _tradeUuid || tradeId || (isTradeFeedItem(tradeFeedItem) && tradeFeedItem.uuid) || ''
  const tradeGroupUuid =
    _tradeGroupUuid || tradeGroupId || (isTradeGroupFeedItem(tradeFeedItem) && tradeFeedItem.uuid) || ''

  const [fetchTrade, failTrade] = useGetTrade(
    { meta: { tradeId: tradeUuid } },
    { paused: (tradeFeedItem && !isLoggedIn()) || !tradeUuid }
  )
  const [fetchTradeGroup, failTradeGroup] = useGettradeGroup(
    { meta: { tradeGroupId: tradeGroupUuid } },
    { paused: (tradeFeedItem && !isLoggedIn()) || !tradeGroupUuid }
  )

  const [fetchComment, failComment] = useGetComment({ meta: { uuid: commentId || '' } }, { paused: !commentId })
  tradeFeedItem = fetchTrade || fetchTradeGroup || tradeFeedItem
  const initialComments = tradeFeedItem?.resources.trades[tradeFeedItem.uuid]?.comments
  const likes = useGetLikes(tradeUuid, LikeTypes.TRADE, tradeFeedItem)
  const rootUserUuid = tradeFeedItem?.user_uuid || ''

  const {
    linkedMemos = [],
    linkedPosts = []
  }: { linkedMemos?: MemoResource[]; linkedPosts?: PostResource[] } = tradeFeedItem
    ? destructTradeFeedItem(tradeFeedItem)
    : {}

  useTrackTradeDetailImpression(tradeFeedItem)

  if (!isModal && (failTrade || failTradeGroup || failComment)) {
    return <NotFound />
  }
  if (isModal && failTrade) {
    return (
      <DetailCard className={isModal && 'modal'}>
        <NotFound />
      </DetailCard>
    )
  }
  if (!tradeFeedItem || (commentId && !fetchComment))
    return (
      <DetailCard className={cx(isModal && 'modal', 'loading')}>
        <BrandSpinner />
      </DetailCard>
    )
  if (isModerator && fetchComment?.archived_at) {
    return (
      <Spacing padding={[0, 1, 1]}>
        <DetailCard>
          <CommentItem
            comment={fetchComment}
            rootType={ParentTypes.TRADE}
            rootUuid={tradeUuid || tradeGroupUuid}
            rootUserUuid={rootUserUuid}
          />
        </DetailCard>
      </Spacing>
    )
  }
  return (
    <DetailCard className={cx(isModal && 'modal', isModal && scrollY)}>
      <TradeHeaderTop tradeFeedItem={tradeFeedItem} detail isModal={isModal} disableActions={!isLoggedIn()} />
      <TradeHeader tradeFeedItem={tradeFeedItem} isPortfolio={false} />
      <SpacerV rem={0.75} />
      <LinkedPosts tradeFeedItem={tradeFeedItem} posts={linkedPosts} />
      <LinkedMemos tradeFeedItem={tradeFeedItem} memos={linkedMemos} />
      {isTradeFeedItem(tradeFeedItem) && <TradeFooterAction tradeFeedItem={tradeFeedItem} detail />}
      {isTradeGroupFeedItem(tradeFeedItem) && <TradeGroupFooterActions tradeFeedItem={tradeFeedItem} detail />}
      <LikeList likes={likes} uuid={tradeUuid} />
      <RootComments
        initialComments={initialComments}
        uuid={tradeUuid || tradeGroupUuid}
        type={ParentTypes.TRADE}
        isModal={!!isModal}
        rootUserUuid={rootUserUuid}
      />
    </DetailCard>
  )
}

export default TradeDetail
