import React, { useMemo, useState } from 'react'
import { UserProfile, useUpdateAlertPreferencesAction } from '@commonstock/common/src/api/profile'
import { Bell, Radio } from '../../theme/NewIcons'
import { useEphemeralModal } from '../modal/Modal'
import { ActionModal } from '../modal/Modals'
import { TextBodyRegular } from '../../composing/CSText'
import { unexpectedError } from '../../dev/sentry'
import useRefetchByKeys from '@commonstock/client/src/react/useRefetchByKeys'
import { SortItem } from '../../components/Sort'
import { SecondaryButton } from '../../composing/CSButton'
import { cx } from '@linaria/core'
import { paneHeaderIconButton } from 'src/components/styles'
import { useTheme } from 'src/theme/ThemeContext'

enum AlertSetting {
  None = 'None',
  All = 'All',
  Memo = 'Memo'
}

const alertSettings = [
  {
    id: 'none',
    title: 'None',
    mode: AlertSetting.None
  },
  {
    id: 'all',
    title: 'All memos and trades',
    mode: AlertSetting.All
  },
  {
    id: 'memo',
    title: 'Only memos',
    mode: AlertSetting.Memo
  }
]

function ProfileAlerts({ profile }: { profile: UserProfile }) {
  const modal = useEphemeralModal()

  const currentAlertPreference = useMemo(() => {
    const { memo_alerts_enabled, trade_alerts_enabled } = profile
    if (!memo_alerts_enabled && !trade_alerts_enabled) return AlertSetting.None
    if (memo_alerts_enabled && !trade_alerts_enabled) return AlertSetting.Memo
    return AlertSetting.All
  }, [profile])

  const onClick = () => {
    modal(<AlertMenu currentAlertPreference={currentAlertPreference} profile={profile} />)
  }

  const { isMobile } = useTheme()

  return (
    <SecondaryButton
      onClick={onClick}
      aria-label={'Toggle notifications for this user'}
      className={cx(paneHeaderIconButton, currentAlertPreference !== AlertSetting.None && 'active')}
      mobile={isMobile}
    >
      <Bell />
    </SecondaryButton>
  )
}

function AlertMenu({
  currentAlertPreference,
  profile
}: {
  currentAlertPreference: AlertSetting
  profile: UserProfile
}) {
  const [alertPrefrence, setAlertPrefrence] = useState(currentAlertPreference)
  const updateAlertPreference = useUpdateAlertPreferencesAction()
  const refetchDependents = useRefetchByKeys('get-feed', 'get-global-feed', 'user-profile', 'profile-username')

  const onClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let mode
    let preferences = { memo_alerts_enabled: false, trade_alerts_enabled: false }
    switch (e.currentTarget.id) {
      case 'none':
        mode = AlertSetting.None
        break
      case 'all':
        mode = AlertSetting.All
        preferences.memo_alerts_enabled = true
        preferences.trade_alerts_enabled = true
        break
      default:
        mode = AlertSetting.Memo
        preferences.memo_alerts_enabled = true
        break
    }
    try {
      await updateAlertPreference({ meta: { uuid: profile.user_uuid }, json: preferences })
      setAlertPrefrence(mode)
      refetchDependents()
    } catch (err) {
      unexpectedError(err)
    }
  }

  return (
    <ActionModal title="Alerts" noPad>
      {alertSettings.map(({ id, mode, title }) => (
        <SortItem key={id} id={id} onClick={onClick}>
          <TextBodyRegular>{title}</TextBodyRegular>
          <Radio active={alertPrefrence === mode} size={1.25} />
        </SortItem>
      ))}
    </ActionModal>
  )
}

export default ProfileAlerts
