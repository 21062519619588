import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { Menu, MenuItem } from '../../components/styles'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import { Routes } from '../nav/constants'
import FollowProfile from '../profile/FollowProfile'
import { HydratedUserProfile, useGetProfile } from '@commonstock/common/src/api/profile'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import { ModalScope, useEphemeralModal, useModal } from '../modal/Modal'
import EditPost from './EditPost'
import { PostFeedItem, PostResource, FeedItem } from '@commonstock/common/src/api/feed'
import { useRefetchPostDependents } from './postHooks'
import { ShareType } from '../../../../common/src/api/link'
import dynamic from 'next/dynamic'

const ShareModal = dynamic(() => import('../share/ShareModal'))
const DeletePost = dynamic(() => import('./DeletePost'))

type Props = {
  postFeedItem: PostFeedItem
  post: PostResource
  user: HydratedUserProfile
  detail?: boolean
}
function PostCardActions({ post, user, postFeedItem, detail }: Props) {
  const modal = useEphemeralModal()
  const { actions, activeModal } = useModal()
  const { userUuid, isModerator } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [userProfile] = useGetProfile({ meta: { user_uuid: post.user_uuid } }, { paused: !isOpen })
  const refetchPostDependents = useRefetchPostDependents()

  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onFinish = useCallback(
    (item: FeedItem) => {
      if (item) {
        refetchPostDependents()
        if (detail) {
          actions.close()
        } else {
          modal(null)
        }
      }
    },
    [actions, detail, modal, refetchPostDependents]
  )

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  const isMyMemo = userUuid === post.user_uuid
  useEventListener('click', (e: any) => !e.target.closest(`#actions-${post.uuid}`) && setIsOpen(false))

  const handleEditPost = useCallback(() => {
    modal(<EditPost postFeedItem={postFeedItem} onFinish={onFinish} />, {
      ...(!detail ? { shallowUrl: Routes.postDetail(post.uuid) } : {}),
      scope: ModalScope.Ephemeral
    })
  }, [detail, modal, onFinish, post.uuid, postFeedItem])
  const handleDeletePost = useCallback(() => {
    const postDetailMatch = window.location.href.match(Routes.postDetail(post.uuid))
    modal(
      <DeletePost uuid={post.uuid} postRoute={!!postDetailMatch} shallowRoute={!!postDetailMatch && !!activeModal} />
    )
  }, [post.uuid, modal, activeModal])
  const handleShare = useCallback(() => modal(<ShareModal type={ShareType.POST} uuid={post.uuid} />), [
    post.uuid,
    modal
  ])

  return (
    <div id={`actions-${post.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={'Show more post actions'}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      {isOpen && (
        <>
          <Menu>
            <MenuItem disabled={user.private} onClick={handleShare}>
              {user.private ? 'Share disabled' : 'Share post'}
            </MenuItem>
            {isMyMemo ? (
              <>
                <MenuItem onClick={handleEditPost}>Edit post</MenuItem>
                <MenuItem isDestructive onClick={handleDeletePost}>
                  Delete post
                </MenuItem>
              </>
            ) : (
              <>
                <FollowProfile profile={user} isDropdown />
                <MuteToggleItem profile={user} />
                {/* <ReportContentMenuItem uuid={post.uuid} title={post.title} contentType={ReportContentType.Memo} /> */}
                {isModerator && (
                  <>
                    <MenuItem isModerator onClick={handleEditPost}>
                      Edit post
                    </MenuItem>
                    {!post.archived_at && (
                      <MenuItem isModerator onClick={handleDeletePost}>
                        Archive post
                      </MenuItem>
                    )}
                  </>
                )}
              </>
            )}
          </Menu>
        </>
      )}
    </div>
  )
}

export default PostCardActions
