import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react'
import { select } from 'd3-selection'
import { transition } from 'd3-transition'
import { easeSin } from 'd3-ease'
import { useLineChart, DataPoint, DataPointArray } from './LineChartContext'
import { line, curveMonotoneX } from 'd3-shape'
import { interpolatePath } from 'd3-interpolate-path'
import { isNumber } from '@commonstock/common/src/utils/format'
import { useTheme } from '../../theme/ThemeContext'

type LineProps = {
  color: string
  animationBegin: number
  animationDuration: number
  strokeWidth: number
  dataKey: 'c' | 'b' | 'v'
}
function Line({ color, animationBegin, animationDuration, strokeWidth, dataKey }: LineProps) {
  const { data, yScale, xScale, coordinates } = useLineChart()
  const lineRef = useRef<SVGPathElement>(null)

  const lineGenerator = useMemo(
    () =>
      line<DataPoint>()
        .defined(d => isNumber(d[dataKey]))
        .x(d => xScale(d.i))
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .y(d => yScale(d[dataKey]!))
        .curve(curveMonotoneX),
    [dataKey, xScale, yScale]
  )
  const chartId = `${Math.floor(Math.random() * 1000000)}`

  const updatePath = useCallback(() => {
    const path = select(lineRef.current)
    const previousPath = path.attr('d')
    // @ts-ignore
    const currentPath: string = lineGenerator(data)

    const transitionPath = transition<DataPointArray>()
      .ease(easeSin)
      .duration(animationDuration)
      .delay(animationBegin)

    if (previousPath === currentPath) return null
    if (!previousPath) {
      const updatedPath = select(lineRef.current)
        .interrupt()
        .attr('d', lineGenerator(data) || '')

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const pathLength = updatedPath.node()!.getTotalLength()

      updatedPath
        .attr('stroke-dashoffset', pathLength)
        .attr('stroke-dasharray', pathLength)
        // @ts-ignore need to discover how to type this
        .transition(transitionPath)
        .attr('stroke-dashoffset', 0)
    } else {
      path
        .attr('stroke-dashoffset', null)
        .attr('stroke-dasharray', null)
        // @ts-ignore need to discover how to type this
        .transition(transitionPath)
        .attrTween('d', () => interpolatePath(previousPath, currentPath))
    }
  }, [lineGenerator, data, animationDuration, animationBegin])

  useEffect(() => {
    if (!lineRef.current) return
    updatePath()
  }, [data, updatePath])

  const { theme } = useTheme()
  // Extract color variable value, because linearGradient stops doesnt allow variables
  const [plainColor, setPlainColor] = useState(color)
  useEffect(() => {
    const themeRoot = document.getElementById('theme-root')
    setPlainColor(
      (themeRoot && getComputedStyle(themeRoot).getPropertyValue(color.replace(/var\((.*)\)/, '$1'))) || color
    )
  }, [color, theme])

  const coordinate = coordinates[data.length - 1]

  return (
    <g>
      <path
        className="chart-line"
        ref={lineRef}
        fill={'none'}
        // If color == plainColor means something went wrong, so we use color and ignore gradient
        stroke={color === plainColor ? color : `url(#linear-gradient__${chartId})`}
        strokeWidth={strokeWidth}
        strokeLinejoin={'round'}
        strokeLinecap={'round'}
      />
      <linearGradient
        id={`linear-gradient__${chartId}`}
        gradientUnits="userSpaceOnUse"
        x1={-5}
        y1={0}
        x2={coordinate?.x}
        y2={0}
      >
        <stop offset="0%" stopColor={plainColor} stopOpacity={0}></stop>
        <stop offset="100%" stopColor={plainColor}></stop>
      </linearGradient>
    </g>
  )
}

export default Line
