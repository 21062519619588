import { css, cx } from '@linaria/core';
import React from 'react';
import { SkeletonBlock } from 'src/components/Skeleton';
import { FlexColumn } from 'src/composing/Flex';
import { SpacerV } from 'src/composing/Spacing';
import { useDev } from 'src/scopes/dev/DevContext';
import { devTransparent } from 'src/theme/AtomicClasses';
import { Colors } from 'src/theme/constants';
export function LinkPreviewSkeleton() {
  const {
    skeletonShow
  } = useDev();
  return <FlexColumn className={cx(containerClass, skeletonShow && devTransparent)}>
      <SkeletonBlock h={18.5} w={'100%'} borderRadius={0} />
      <FlexColumn className={detailClass}>
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125} />
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125 + 1.1875} />
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.125} />
        <SkeletonBlock h={1.1875} w={'90%'} />
        <SpacerV rem={0.5} />
        <SkeletonBlock h={1.1875} w={'30%'} />
      </FlexColumn>
    </FlexColumn>;
}
const containerClass = "c17yrtal";
const detailClass = "d1kq29w2";

require("../../../.linaria-cache/packages/oxcart/src/scopes/posts/LinkPreview.skeleton.linaria.module.css");