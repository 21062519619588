import {
  MemoFeedItem,
  PostFeedItem,
  TradeFeedItem,
  TradeGroupFeedItem,
  TradeGroupResource,
  TradeResource
} from '@commonstock/common/src/api/feed'
import { useMemo } from 'react'
import LinkedResourceCarousel from 'src/components/LinkedResourceCarousel'
import { SpacerV } from 'src/composing/Spacing'
import { feedItemToTrade } from '../memo/utils'
import { TradeDetailLink } from './TradeCard'
import TradeHeader from './TradeHeader'

type Props = {
  feedItem: MemoFeedItem | PostFeedItem
  trades: (TradeResource | TradeGroupResource)[]
}

function LinkedTrades({ feedItem, trades }: Props) {
  const tradeFeedItems: Array<TradeFeedItem | TradeGroupFeedItem> = useMemo(() => {
    return trades
      .map(trade => feedItemToTrade(feedItem, trade))
      .filter((item): item is TradeFeedItem | TradeGroupFeedItem => !!item)
  }, [feedItem, trades])

  if (!tradeFeedItems.length) return null
  return (
    <>
      <SpacerV />
      <LinkedResourceCarousel label="trades" height={5.6875}>
        {tradeFeedItems.map(tradeFeedItem => (
          <TradeDetailLink key={tradeFeedItem.uuid} tradeFeedItem={tradeFeedItem}>
            <TradeHeader tradeFeedItem={tradeFeedItem} isLinkedTrade />
          </TradeDetailLink>
        ))}
      </LinkedResourceCarousel>
    </>
  )
}

export default LinkedTrades
