import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'

type RelatedItems = {
  item_type: string
  item_uuid: string
  linked_trades: string[]
}

type RelatedTradesUpdateParams = {
  meta: {
    memoId: string
  }
  json: {
    linked_trades: string[]
  }
}

export const useUpdateRelatedTradeAction = createUseFetchAction<RelatedItems, RelatedTradesUpdateParams>({
  key: 'related-trade-put',
  path: ({ meta: { memoId } }) => `/related/${memoId}`,
  method: HttpMethods.Put
})

type RelatedMemosUpdateParams = {
  meta: {
    tradeId: string
  }
  json: {
    linked_items: string[]
  }
}

export const useUpdateRelatedMemoAction = createUseFetchAction<RelatedItems, RelatedMemosUpdateParams>({
  key: 'related-memo-put',
  path: ({ meta: { tradeId } }) => `/related/trades/${tradeId}`,
  method: HttpMethods.Put
})
