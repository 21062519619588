import React from 'react'
import { useCallback } from 'react'
import { usePostLoginAction, usePostSignupAction } from '@commonstock/common/src/api/auth'
import { useAuth } from './AuthContext'
import config from '../../config'
import { Routes } from '../nav/constants'
import { getPersistedState, usePersistedState } from '@commonstock/common/src/utils/usePersistedState'
import { useEphemeralModal } from '../modal/Modal'
import Welcome from './Welcome'
import { track } from '../analytics/mixpanel'
import { useGetMyProfile } from '@commonstock/common/src/api/profile'
import { Authenticated } from './constants'
import { captureException } from '../../dev/sentry'
import { InitSessionTokenKey, InitRefreshTokenKey } from './constants'
import { useRouter } from 'next/router'
import { AuthenticateRedirectKey } from 'src/client/authenticator'

export type PasswordLoginData = {
  username: string
  password: string
}

type SignupData = {
  email: string
  password: string
}

export function useLoginRedirect() {
  const router = useRouter()
  const { authenticated, myProfile: profile } = useAuth()
  let [, , , getProfile] = useGetMyProfile(null, { paused: authenticated !== Authenticated.Yes })

  return useCallback(async () => {
    // make sure we know the profile before we perform the redirect, so we know where to go
    let lazyProfile = profile
    if (!lazyProfile) {
      try {
        let resource = await getProfile()
        lazyProfile = resource.success?.payload
      } catch (err) {
        captureException(err, { context: 'loginRedirect, unexpected error finding profile' })
        router.replace(Routes.Home)
      }
    }
    requestIdleCallback(
      async () => {
        const redirect = (await getPersistedState(AuthenticateRedirectKey)) || ''
        router.replace(
          lazyProfile?.username
            ? !redirect || redirect.startsWith(Routes.Login)
              ? Routes.Home
              : redirect
            : Routes.OnboardProfile
        )
      },
      { timeout: 500 }
    )
  }, [profile, getProfile, router])
}

export function useLoginWithPassword() {
  track('LoggedIn:Impression', { type: 'email' })
  const postLogin = usePostLoginAction()
  const { startSession } = useAuth()
  const [, initSessionTokenSet] = usePersistedState<string | null>(InitSessionTokenKey, null)
  const [, initRefreshTokenSet] = usePersistedState<string | null>(InitRefreshTokenKey, null)
  const router = useRouter()

  const loginRedirect = useLoginRedirect()

  let loginWithPassword = useCallback(
    ({ username, password }: PasswordLoginData) => {
      return postLogin({
        json: {
          grant_type: 'password',
          scope: 'openid email profile',
          client_id: config.auth0ClientId,
          username,
          password
        }
      }).then(async data => {
        let { confirmed, jwt } = await startSession(data?.success?.payload.access_token || null)
        if (!confirmed) {
          initSessionTokenSet(data?.success?.payload.access_token || null)
          initRefreshTokenSet(jwt.refresh_token || null)
          router.push(Routes.Verify)
        } else {
          loginRedirect()
        }
      })
    },
    [startSession, postLogin, initSessionTokenSet, initRefreshTokenSet, loginRedirect, router]
  )
  return loginWithPassword
}

export function useSignupWithPassword() {
  let postSignup = usePostSignupAction()
  let loginWithPassword = useLoginWithPassword()
  let signupWithPassword = useCallback(
    (signup: SignupData) => {
      // const body = uriEncode(signup)
      return postSignup({
        json: { ...signup, client_id: config.auth0ClientId, connection: 'Username-Password-Authentication' }
      }).then(data => {
        // @TODO need to update the client type so it knows this will always resolve with success
        if (data?.success) {
          return loginWithPassword({ username: signup.email, password: signup.password })
        } else throw new Error('## Failed Signup')
      })
    },
    [loginWithPassword, postSignup]
  )
  return signupWithPassword
}

export function useGoToWelcome() {
  const router = useRouter()
  const modal = useEphemeralModal()

  const goToWelcome = useCallback(() => {
    router.push(Routes.Home)
    // @NOTE modal not displaying unless we have the settimeout
    setTimeout(() => modal(<Welcome />, { manualClose: true }), 500)
    track('Onboard:Success')
  }, [modal, router])

  return goToWelcome
}
