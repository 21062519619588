import React from 'react';
import LikeIcon from '../likes/LikeIcon';
import { LikeTypes } from '@commonstock/common/src/api/like';
import { Spacing } from '../../composing/Spacing';
import { MemoFeedItem } from '@commonstock/common/src/api/feed';
import { destructMemoFeedItem } from './utils';
import { CardLayoutTypes } from 'src/scopes/feed/constants';
import { css } from '@linaria/core';
import { Colors } from 'src/theme/constants';

function MemoColumnActions({
  memoFeedItem,
  cardLayout
}: {
  memoFeedItem: MemoFeedItem;
  cardLayout?: CardLayoutTypes;
}) {
  let {
    memo,
    user
  } = destructMemoFeedItem(memoFeedItem);
  if (!memo || !user) return null;
  return <div className={backgroundColumn}>
      <Spacing margin={[0, 1, 0, 0.5]}>
        <div>
          <LikeIcon likes={memo.likes} parentUuid={memo.uuid} parentType={LikeTypes.MEMO_PLUS} authorUuid={user.uuid} cardLayout={cardLayout} />
        </div>
      </Spacing>
    </div>;
}

export default MemoColumnActions;
const backgroundColumn = "b104th6s";

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo/MemoColumnActions.linaria.module.css");