import React, { Fragment } from 'react';
import { css } from '@linaria/core';
import { Periods, TagItemType } from '@commonstock/common/src/types';
import { useGetTopMemos, MemoFeedItem, PostFeedItem, FeedItem, MemoFeedOutput, PostFeedOutput } from '@commonstock/common/src/api/feed';
import { SpacerV } from '../../composing/Spacing';
import { DiscoverTopMemosSkeleton } from './Discover.skeleton';
import { Card } from '../../components/styles';
import ListRowHeader from '../../components/ListRowHeader';
import MemoCardSmall from '../memo/MemoCardSmall';
import { SkeletonWrapper } from '../../components/SuspenseSkeleton';
import { useGetUsersPosts } from '@commonstock/common/src/api/post';
import PostCardSmall from 'src/scopes/posts/PostCardSmall';
import { useGetUsersMemos } from '@commonstock/common/src/api/memo';
import { useGetTaggedMemosPreview } from '@commonstock/common/src/api/tag';
export enum RelatedContentTypes {
  ByAuthor = 'ByAuthor',
  ByAsset = 'ByAsset',
  ByTrending = 'ByTrending',
}

function getTitle(relatedBy: RelatedContentTypes) {
  let title = '';

  switch (relatedBy) {
    case RelatedContentTypes.ByAuthor:
      title = 'By this author';
      break;

    case RelatedContentTypes.ByAsset:
      title = 'More on this asset';
      break;

    case RelatedContentTypes.ByTrending:
      title = 'Trending this week';
      break;

    default:
      break;
  }

  return title;
}

function useRelatedBy(relatedBy: RelatedContentTypes, {
  user_uuid,
  resources
}: PostFeedItem | MemoFeedItem | FeedItem): [MemoFeedOutput[] | MemoFeedOutput | undefined, PostFeedOutput[] | undefined, boolean, boolean] {
  // ByAuthor
  const [relatedByUserMemos, relatedByUserMemosFail,,,, firstPageLoadedAuthorMemo] = useGetUsersMemos({
    query: {
      limit: 2,
      target_uuid: user_uuid || ''
    }
  }, {
    paused: !user_uuid || relatedBy !== RelatedContentTypes.ByAuthor
  });
  const [relatedByUserPosts, relatedByUserPostsFail,,,, firstPageLoadedAuthorPost] = useGetUsersPosts({
    query: {
      limit: 1,
      target_uuid: user_uuid || ''
    }
  }, {
    paused: !user_uuid || relatedBy !== RelatedContentTypes.ByAuthor
  }); // ByAsset

  const {
    symbol,
    type
  } = Object.values(resources.assets)[0] || {
    symbol: '',
    type: ''
  };
  const [relatedByAssetMemos, relatedByAssetMemosFail, relatedByAssetMemosPending] = useGetTaggedMemosPreview({
    query: {
      tag: `ASSET|${symbol}:${type}`,
      content_types: TagItemType.MEMO_PLUS,
      limit: 3
    }
  }); // @TODO: We don't have an endpoint for tagged posts
  // ByTrending

  const [relatedByTrendingMemos, relatedByTrendingMemosFail,,,, firstPageLoadedTrendingMemo] = useGetTopMemos({
    query: {
      period: Periods['1W'],
      limit: 3
    }
  });

  if (relatedBy === RelatedContentTypes.ByAuthor) {
    return [relatedByUserMemos, relatedByUserPosts, firstPageLoadedAuthorPost && firstPageLoadedAuthorMemo, !!relatedByUserMemosFail && !!relatedByUserPostsFail];
  }

  if (relatedBy === RelatedContentTypes.ByAsset) {
    return [!symbol || !type ? [] : relatedByAssetMemos, undefined, !relatedByAssetMemosPending, !!relatedByAssetMemosFail];
  }

  if (relatedBy === RelatedContentTypes.ByTrending) {
    return [relatedByTrendingMemos, undefined, !!firstPageLoadedTrendingMemo, !!relatedByTrendingMemosFail];
  }

  return [undefined, undefined, false, false];
}

export default function RelatedBy({
  relatedBy,
  feedItem
}: {
  relatedBy: RelatedContentTypes;
  feedItem: PostFeedItem | MemoFeedItem | FeedItem;
}) {
  let relatedMemos: MemoFeedItem[] | undefined = undefined;
  let relatedPosts: PostFeedItem[] | undefined = undefined;
  const [relatedByMemos, relatedByPosts, firstPageLoaded, failed] = useRelatedBy(relatedBy, feedItem);
  relatedMemos = Array.isArray(relatedByMemos) ? relatedByMemos?.flatMap(item => item.items) : relatedByMemos?.items;
  relatedPosts = relatedByPosts?.flatMap(item => item.items);
  return <SkeletonWrapper pending={!firstPageLoaded} skeleton={<>
          <DiscoverTopMemosSkeleton />
          <SpacerV />
        </>} failed={failed || relatedMemos && !relatedMemos.length || relatedPosts && !relatedPosts.length}>
      {!!relatedMemos?.length && <Card className={sectionClass}>
          <ListRowHeader title={getTitle(relatedBy)} />
          {relatedMemos.map(memo => <Fragment key={memo.uuid}>
              <SpacerV />
              <MemoCardSmall memoFeedItem={memo} />
            </Fragment>)}
          {!!relatedPosts?.map(post => <Fragment key={post.uuid}>
                <SpacerV />
                <PostCardSmall postFeedItem={post} />
              </Fragment>)}
        </Card>}
      <SpacerV />
    </SkeletonWrapper>;
}
const sectionClass = "s1nzvvnx";

require("../../../.linaria-cache/packages/oxcart/src/scopes/discover/RelatedBy.linaria.module.css");