import {
  FeedResourceType,
  PostFeedItem,
  PostResource,
  TradeFeedItem,
  TradeGroupFeedItem,
  TradeGroupResource,
  TradeResource,
  FeedReason
} from '@commonstock/common/src/api/feed'
import { HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { MentionAttachments } from '@commonstock/common/src/types/mentions'

type DestructedPost = {
  post?: PostResource
  user?: HydratedUserProfile
  reason_code: FeedReason | null
  reason_text: string | null
  mentionAttachments: MentionAttachments
  linkedTrades: Array<TradeResource | TradeGroupResource>
}
export function destructPostFeedItem(postFeedItem: PostFeedItem): DestructedPost {
  const { resources, reason_code, reason_text } = postFeedItem
  let post: PostResource | undefined = resources.posts[postFeedItem.uuid]
  let user: HydratedUserProfile | undefined = resources.users[postFeedItem.user_uuid]
  // BE return a wrong object when it fails to grab it from DB, so we need to check for the current uuid of them
  post = postFeedItem.uuid === post?.uuid ? post : undefined
  user = postFeedItem.user_uuid === user?.uuid ? user : undefined

  const asset_mentions = post?.asset_mentions.map(id => resources.assets[id] || null).filter(Boolean)
  const user_mentions = post?.user_mentions.map(id => resources.users[id] || null).filter(Boolean)
  const link_previews = Object.values(post?.links || {})
  const mentionAttachments: MentionAttachments = { asset_mentions, user_mentions, link_previews }

  const linkedTrades: Array<TradeResource | TradeGroupResource> =
    post?.related_trades?.map(id => resources.trades[id] || resources.trade_groups[id] || null).filter(Boolean) || []

  return { post, user, reason_code, reason_text, mentionAttachments, linkedTrades }
}

export function postFeedItemToTrade(
  postFeedItem: PostFeedItem,
  trade: TradeResource | TradeGroupResource
): TradeFeedItem | TradeGroupFeedItem | null {
  if (!postFeedItem.resources.trades[trade.uuid] && !postFeedItem.resources.trade_groups[trade.uuid]) return null
  return {
    ...postFeedItem,
    created_at: trade.created_at,
    resource_type: 'bought' in trade ? FeedResourceType.TRADE_GROUP : FeedResourceType.TRADE,
    uuid: trade.uuid,
    user_uuid: trade.user_uuid
  }
}

// We use the same regex as BE, so avoid changing it without changing on BE first, otherwise, your link wont be displayed after saving
const urlRegexString = /(?:^|\s)((?:(?:https?|http?):\/\/)?[a-z0-9-]+\.[a-z]{2,}[^\s,]*)/
export const urlRegex = new RegExp(urlRegexString, 'gi')

export const getLinks = (text?: string) => {
  return text?.match(urlRegex)?.map(e => e.trim()) || []
}
