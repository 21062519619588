import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { Asset } from '../types'

type MarketSessionPayload = {
  latest_market_time: string
  reference_time: string
  is_market_open: boolean
}
export const useMarketSession = createUseFetch<MarketSessionPayload>({
  key: 'market-session',
  path: '/mds/market/session/latest'
})

type AssetSearchParams = {
  query: {
    limit: number
    term: string
  }
}

export const useAssetSearchAction = createUseFetchAction<Asset[], AssetSearchParams>({
  key: 'market-asset-search-action',
  path: '/mds/asset/search'
})

export const useAssetSearch = createUseFetch<Asset[], AssetSearchParams>({
  key: 'market-asset-search',
  path: '/mds/asset/search'
})

export type TopMovers = {
  reference_price: number
  current_price: number
  ticker: string
  short_name: string
  change_value: number
  change_percentage: number
  asset_type: string
}

export const useTopMovers = createUseFetch<TopMovers[]>({
  key: 'top-movers',
  path: '/mds/top-movers'
})
