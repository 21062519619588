import { isMemoFeedItem, isPostFeedItem, MemoFeedItem, PostFeedItem } from '@commonstock/common/src/api/feed'
import { useGetProfile } from '@commonstock/common/src/api/profile'
import { destructMemoFeedItem } from '../memo/utils'
import { useAuth } from '../auth/AuthContext'
import ProfileBlock from './ProfileBlock'
import { TextDisplayMedium } from 'src/composing/CSText'
import { SpacerV } from 'src/composing/Spacing'
import { destructPostFeedItem } from 'src/scopes/posts/utils'

type Props = {
  feedItem: MemoFeedItem | PostFeedItem
}

function AuthorBlock({ feedItem }: Props) {
  const { userUuid } = useAuth()
  let user
  if (isMemoFeedItem(feedItem)) {
    let { user: memoUser } = destructMemoFeedItem(feedItem)
    user = memoUser
  }
  if (isPostFeedItem(feedItem)) {
    let { user: postUser } = destructPostFeedItem(feedItem)
    user = postUser
  }
  const [profile] = useGetProfile({ meta: { user_uuid: user?.uuid || '' } }, { paused: !user })

  return (
    <>
      <TextDisplayMedium>Author</TextDisplayMedium>
      <SpacerV />
      <ProfileBlock isMe={userUuid === profile?.user_uuid} profile={profile} pending={!profile} followButton />
    </>
  )
}

export default AuthorBlock
