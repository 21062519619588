import { FollowStatus, HydratedUserProfile } from '@commonstock/common/src/api/profile'
import { AdminMessage, FileMessage, UserMessage, GroupChannel, BaseChannel, Member } from 'sendbird'

export type Message = AdminMessage | FileMessage | UserMessage

export function isUserMessage(message: Message): message is UserMessage {
  return message && (message as UserMessage).isUserMessage()
}

export function isAdminMessage(message: Message): message is AdminMessage {
  return message && (message as AdminMessage).isAdminMessage()
}

export function isFileMessage(message: Message): message is FileMessage {
  return message && (message as FileMessage).isFileMessage()
}

export function isGroupChannel(channel: BaseChannel): channel is GroupChannel {
  return (channel as GroupChannel).isGroupChannel()
}

export type Messages = Message[]

export function SendbirdMemberToHydratedProfile(m: Member): HydratedUserProfile {
  // @ts-ignore display_name is a custom prop
  const { display_name = '', verified = 'not_verified' } = m.metaData
  return {
    username: m.nickname,
    uuid: m.userId,
    picture: m.profileUrl,
    name: display_name,
    verified: verified !== 'not_verified',
    follow_status: FollowStatus.NOT_FOLLOWED,
    private: false,
    followed_by_me: false,
    follower_assets: null
  }
}
