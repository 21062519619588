import React, { createContext, useMemo, ReactNode, useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useChat, sendbird } from './ChatContext'
import { ChannelHandlers } from './constants'
import { GroupChannel, GroupChannelListQuery } from 'sendbird'
import { isGroupChannel } from './types'
import { ChannelTypes } from '@commonstock/common/src/api/chat'
import { captureException } from '../../dev/sentry'
import { setUsersChannelCount } from 'src/scopes/analytics/mixpanel'

type MappedChannels = { [key: string]: GroupChannel }

type ChannelsValue = {
  loadedChannels: boolean
  getUpdatedChannelAndSelect: (s: string) => Promise<any>
  currentChannel: GroupChannel | null
  lastUpdated: number
  channels: MappedChannels
}

const ChannelsContext = createContext<ChannelsValue>({
  loadedChannels: false,
  getUpdatedChannelAndSelect: async () => {},
  currentChannel: null,
  lastUpdated: 0,
  channels: {}
})
ChannelsContext.displayName = 'ChannelsContext'

const ChannelsProvider = ({ children }: { children: ReactNode }) => {
  const channelsRef = useRef<MappedChannels | null>(null)
  const { isConnected, setChannelUrl, channelUrl } = useChat()
  const [loadedChannels, setLoadedChannels] = useState(false)
  const [channels, setChannels] = useState<MappedChannels>({})
  const channelHandlersRef = useRef<SendBird.ChannelHandler>()
  channelsRef.current = channels

  const currentChannel = channelUrl ? channels[channelUrl] : null

  useEffect(() => {
    if (isConnected) {
      const channelListQuery = sendbird.GroupChannel.createMyGroupChannelListQuery()
      channelListQuery.includeEmpty = true
      channelListQuery.limit = 100
      channelListQuery.order = 'latest_last_message'
      setChannels({})
      loadChannels(channelListQuery, setLoadedChannels, setChannels)
    }
  }, [isConnected])

  useEffect(() => {
    if (loadedChannels && channelsRef.current) {
      const channels = Object.values(channelsRef.current)
      setUsersChannelCount({
        direct_message_channel_count: channels.filter(c => c.customType === ChannelTypes.Direct).length,
        private_channel_count: channels.filter(c => c.customType === ChannelTypes.Private).length
      })
    }
  }, [loadedChannels])

  const [lastUpdated, setLastUpdated] = useState(new Date().getTime())

  useEffect(() => {
    if (!channelUrl || !loadedChannels) return
    const channel: GroupChannel | undefined = channels[channelUrl]

    if (!channel || channel.myMemberState !== 'joined') {
      captureException(new Error('Channel not found'), { channels, channelUrl })
      console.log('## channel not found', channelUrl, channels)
      setChannelUrl(null)
    }
  }, [channelUrl, channels, isConnected, loadedChannels, setChannelUrl, lastUpdated])

  const addOrUpdateChannel = useCallback((chnl: GroupChannel) => {
    isGroupChannel(chnl) && setChannels(prev => ({ ...prev, [chnl.url]: chnl }))
    setLastUpdated(new Date().getTime())
  }, [])

  const removeChannel = useCallback(
    (url: string) =>
      setChannels(prev =>
        Object.keys(prev).reduce((obj, key) => (key !== url ? { ...obj, [key]: prev[key] } : obj), {})
      ),
    []
  )

  const getUpdatedChannelAndSelect = useCallback(
    (url: string) =>
      new Promise(resolve => {
        sendbird.GroupChannel.getChannel(url, (chnl, error) => {
          if (error) return console.log('## get channel err:', error)
          addOrUpdateChannel(chnl)
          setChannelUrl(url)
          resolve(chnl)
        })
      }),
    [addOrUpdateChannel, setChannelUrl]
  )

  useEffect(() => {
    if (isConnected && channels && !channelHandlersRef.current) {
      let ChannelHandler = new sendbird.ChannelHandler()
      channelHandlersRef.current = ChannelHandler
      ChannelHandler.onChannelChanged = addOrUpdateChannel
      ChannelHandler.onChannelDeleted = removeChannel
      ChannelHandler.onUserLeft = (chnl, user) => {
        if (user.userId === sendbird.currentUser?.userId) removeChannel(chnl.url)
        else addOrUpdateChannel(chnl)
      }
      ChannelHandler.onChannelHidden = addOrUpdateChannel
      ChannelHandler.onUserJoined = addOrUpdateChannel
      ChannelHandler.onUserReceivedInvitation = addOrUpdateChannel
      ChannelHandler.onUserDeclinedInvitation = (chnl: GroupChannel, _, invitee) => {
        if (invitee.userId === sendbird.currentUser?.userId) removeChannel(chnl.url)
        else addOrUpdateChannel(chnl)
      }
      sendbird.addChannelHandler(ChannelHandlers.ChannelList, ChannelHandler)
    }
  }, [channels, isConnected, removeChannel, addOrUpdateChannel])

  const value: ChannelsValue = useMemo(
    () => ({
      loadedChannels,
      getUpdatedChannelAndSelect,
      currentChannel,
      lastUpdated,
      channels
    }),
    [channels, currentChannel, getUpdatedChannelAndSelect, lastUpdated, loadedChannels]
  )

  return <ChannelsContext.Provider value={value}>{children}</ChannelsContext.Provider>
}

const useChannels = () => {
  const context = useContext(ChannelsContext)
  return context
}

export { ChannelsProvider, useChannels }

export function regroupChannels(channelMap: MappedChannels) {
  const groupedChannelMap: { [k: string]: GroupChannel[] } = {
    directChannels: [],
    groupChannels: [],
    invitedChannels: [],
    joinedChannels: []
  }
  if (!channelMap) return groupedChannelMap

  const allChannels = Object.values(channelMap)
    .sort((a, b) => (b.lastMessage?.createdAt || b.createdAt) - (a.lastMessage?.createdAt || a.createdAt))
    .filter(chnl => !chnl.isHidden)

  allChannels.forEach(channel => {
    if (channel.myMemberState === 'invited') groupedChannelMap.invitedChannels.push(channel)
    else if (channel.myMemberState === 'joined') {
      groupedChannelMap.joinedChannels.push(channel)
      if (channel.customType === ChannelTypes.Direct) groupedChannelMap.directChannels.push(channel)
      if (channel.customType === ChannelTypes.Private) groupedChannelMap.groupChannels.push(channel)
    }
  })
  return groupedChannelMap
}

export function hideChannel(channel: GroupChannel) {
  // @NOTE: the true param allows the channel to unhide when a message is recived
  // the false param allows the user to see previous messages when the channel unhides
  channel.hide(false, true, (_res, error) => {
    if (error) return console.log('## hideChannel err:', error)
  })
}

function loadChannels(
  channelListQuery: GroupChannelListQuery,
  setLoadedChannels: (value: boolean) => void,
  setChannels: (value: React.SetStateAction<MappedChannels>) => void
) {
  setLoadedChannels(false)
  if (channelListQuery.hasNext) {
    channelListQuery.next(function(channelList, err) {
      if (err) {
        // @TODO add error messaging and retry logic
        console.error('## chat: failed to load channels', err)
        return
      }
      const newChannels = Object.assign({}, ...channelList.map(c => ({ [c.url]: c })))
      setChannels(prev => ({ ...prev, ...newChannels }))
      loadChannels(channelListQuery, setLoadedChannels, setChannels)
    })
  } else setLoadedChannels(true)
}
