import React, { useCallback, useState } from 'react'
import { More } from '../../theme/NewIcons'
import { IconButton } from '../../composing/CSButton'
import { Menu, MenuItem } from '../../components/styles'
import useEventListener from '@commonstock/common/src/utils/useEventListener'
import { Routes } from '../nav/constants'
import { useGoto } from '../nav/utils'
import FollowProfile from '../profile/FollowProfile'
import { HydratedUserProfile, useGetProfile } from '@commonstock/common/src/api/profile'
import { ArchivedContentType } from '@commonstock/common/src/types'
import { MuteToggleItem } from '../profile/ProfileButtons'
import { useAuth } from '../auth/AuthContext'
import { ReportContentType } from '@commonstock/common/src/api/report'
import ReportContentMenuItem from '../../components/ReportContent'
import Unarchive from '../../components/Unarchive'
import { MemoResource } from '@commonstock/common/src/api/feed'
import { useEphemeralModal, useModal } from '../modal/Modal'
import { ShareType } from '../../../../common/src/api/link'
import dynamic from 'next/dynamic'

const ShareModal = dynamic(() => import('../share/ShareModal'))
const DeleteMemo = dynamic(() => import('./DeleteMemo'))

type Props = {
  memo: MemoResource
  user: HydratedUserProfile
}
function MemoCardActions({ memo, user }: Props) {
  const modal = useEphemeralModal()
  const { activeModal } = useModal()
  const { userUuid, isModerator } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const [userProfile] = useGetProfile({ meta: { user_uuid: memo.user_uuid } }, { paused: !isOpen })

  user = userProfile ? { ...userProfile, uuid: userProfile.user_uuid } : user

  const onClickMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }, [])

  const isMyMemo = userUuid === memo.user_uuid
  useEventListener('click', (e: any) => !e.target.closest(`#actions-${memo.uuid}`) && setIsOpen(false))
  const gotoEditMemo = useGoto(Routes.editMemo(memo.uuid))

  const onClickEdit = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      gotoEditMemo()
    },
    [gotoEditMemo]
  )

  const handleDeleteMemo = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }

      const memoDetailMatch = window.location.href.match(Routes.memoDetail(memo.uuid))
      const editMemoMatch = window.location.href.match(Routes.editMemo(memo.uuid))
      const memoRoute = !!memoDetailMatch || !!editMemoMatch
      modal(<DeleteMemo uuid={memo.uuid} memoRoute={memoRoute} shallowRoute={memoRoute && !!activeModal} />)
    },
    [activeModal, memo.uuid, modal]
  )
  const handleShare = useCallback(
    (e?: any) => {
      if (e && 'preventDefault' in e) {
        e.preventDefault()
        e.stopPropagation()
      }
      modal(<ShareModal type={ShareType.MEMO_PLUS} uuid={memo.uuid} />)
    },
    [memo.uuid, modal]
  )

  return (
    <div id={`actions-${memo.uuid}`}>
      <IconButton onClick={onClickMenu} aria-label={'Show more memo actions'}>
        <More active={isOpen} size={1.25} />
      </IconButton>
      {isOpen && (
        <>
          <Menu>
            <MenuItem disabled={user.private} onClick={handleShare}>
              {user.private ? 'Share disabled' : 'Share memo'}
            </MenuItem>
            {isMyMemo ? (
              <>
                <MenuItem onClick={onClickEdit}>Edit memo</MenuItem>
                <MenuItem isDestructive onClick={handleDeleteMemo}>
                  Delete memo
                </MenuItem>
              </>
            ) : (
              <>
                <FollowProfile profile={user} isDropdown />
                <MuteToggleItem profile={user} />
                <ReportContentMenuItem uuid={memo.uuid} title={memo.title} contentType={ReportContentType.Memo} />
                {isModerator && (
                  <>
                    <MenuItem isModerator onClick={gotoEditMemo}>
                      Edit memo
                    </MenuItem>
                    {memo.archived_at ? (
                      <Unarchive uuid={memo.uuid} contentType={ArchivedContentType.MEMO_PLUS} />
                    ) : (
                      <MenuItem isModerator onClick={handleDeleteMemo}>
                        Archive memo
                      </MenuItem>
                    )}
                  </>
                )}
              </>
            )}
          </Menu>
        </>
      )}
    </div>
  )
}

export default MemoCardActions
